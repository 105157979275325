import { useMemo, useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
// styling
import { StyledDivider } from 'components/domains/Wallet/DirectPaymentDrawerContent/styled'
import {
  StyledHelpIcon,
  StyledIconImg,
  StyledIconRadio,
  StyledInfoRadio,
  StyledWrapperInfo,
  TopUpButton,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
// component
import Box from 'components/commons/Box'
import PaymentInfoModal from '../../Payment/PaymentInfoModal'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
// lib
import theme from 'theme'
import { useTranslation } from 'react-i18next'
import { IProductsProps } from 'services/payment'
import { IPaymentMethod } from 'types/payment'
import PaymentCardIcon from 'components/domains/Payment/PaymentCardIcon'

export interface ICartData {
  invoice_id?: string
  products: Array<IProductsProps>
  payment_method: Array<IPaymentMethod> | Array<IPaymentMethod>
}
interface IPaymentMethodRadio {
  paymentType: number
  cartValues: ICartData
  handlePaymentTypeChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void
  topUpCoinHandler?: () => void
  isPaymentLoading: boolean
  isPageView?: boolean
  tokenizationData?: any
  setModalCardForm?: Function
}
export default function PaymentMethodRadio({
  paymentType = 0,
  cartValues = null,
  handlePaymentTypeChange = () => {},
  topUpCoinHandler = () => {},
  isPaymentLoading,
  isPageView,
  tokenizationData,
  setModalCardForm,
}: IPaymentMethodRadio) {
  const { t } = useTranslation()
  const [openInfoModal, setOpenInfoModal] = useState(false)

  const maskedCardNumber = useMemo(() => {
    return tokenizationData?.masked_card_number.slice(-4)
  }, [tokenizationData])
  const bankName = useMemo(() => {
    return tokenizationData?.card_info?.bank
  }, [tokenizationData])
  const cardType = useMemo(() => {
    return tokenizationData?.card_info?.type.toLowerCase()
  }, [tokenizationData])

  return (
    <FormControl
      style={{
        width: '100%',
      }}
    >
      <RadioGroup
        value={paymentType}
        onChange={handlePaymentTypeChange}
      >
        {cartValues?.payment_method[paymentType] && (
          <PaymentInfoModal
            data-testid={`c-payment-info-modal-${paymentType}`}
            paymentMethod={cartValues?.payment_method[paymentType]}
            openInfo={openInfoModal}
            setCloseInfo={() => {
              setOpenInfoModal(false)
            }}
          />
        )}
        {cartValues?.payment_method.map(
          (paymentItem, paymentItemIndex) => {
            return (
              <>
                <Box
                  className="b-payment-select-method"
                  id={`b-payment-select-method-${paymentItem.code
                    .replace(/_/g, '-')
                    .toLocaleLowerCase()}`}
                  key={paymentItemIndex}
                  onClick={() => {
                    if (
                      (cartValues?.payment_method[paymentType]
                        ?.code === 'PAYMENT_CARD' &&
                        paymentItem?.code) === 'PAYMENT_CARD'
                    ) {
                      return setModalCardForm(true)
                    } else {
                      return null
                    }
                  }}
                >
                  <Box>
                    <FormControlLabel
                      sx={{
                        width: '100%',
                        backgroundColor: isPageView
                          ? theme.palette.background.tertiery
                          : paymentType === paymentItemIndex &&
                            theme.palette.background.tertiery,
                        marginBottom: isPageView ? '12px' : 0,
                        borderRadius: '6px',
                        padding: '8px 8px',
                        '& .MuiFormControlLabel-label': {
                          display: 'flex',
                          width: '100%',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 20,
                        },
                        marginLeft: 0,
                      }}
                      key={paymentItemIndex}
                      value={paymentItemIndex}
                      control={<Radio disabled={isPaymentLoading} />}
                      label={
                        <Box
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box>
                            <StyledIconRadio>
                              <Box
                                sx={{
                                  width: '20px',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  marginRight: '10px',
                                }}
                              >
                                <StyledIconImg
                                  sx={{
                                    width: '100%',
                                    padding:
                                      paymentItem?.code ===
                                      'TIPTIP_COIN'
                                        ? '2px'
                                        : '0px !important',
                                  }}
                                  src={paymentItem.black_icon}
                                />
                              </Box>
                              {paymentItem.name}
                              {(paymentItem?.code === 'TIPTIP_COIN' ||
                                paymentItem?.code === 'H2H_BCA_VA' ||
                                paymentItem?.code === 'KREDIVO') && (
                                <StyledHelpIcon
                                  onClick={() => {
                                    if (!isPaymentLoading)
                                      setOpenInfoModal(true)
                                  }}
                                />
                              )}
                            </StyledIconRadio>
                            {cartValues?.payment_method[paymentType]
                              ?.code === 'TIPTIP_COIN' &&
                              paymentItem?.coin?.balance <
                                paymentItem?.coin?.total_price && (
                                <StyledWrapperInfo>
                                  <StyledInfoRadio
                                    sx={{
                                      color: theme.palette.error.main,
                                    }}
                                  >
                                    {t(
                                      'paymentMethodV2WidgetCoinError',
                                      {
                                        balance:
                                          paymentItem?.coin?.balance,
                                      },
                                    )}
                                  </StyledInfoRadio>
                                </StyledWrapperInfo>
                              )}
                          </Box>
                          {cartValues?.payment_method[paymentType]
                            ?.code === 'TIPTIP_COIN' &&
                            paymentItem?.coin?.balance <
                              paymentItem?.coin?.total_price && (
                              <TopUpButton
                                style={{ marginRight: 10 }}
                                disabled={isPaymentLoading}
                                buttonVariant="filled"
                                onClick={topUpCoinHandler}
                              >
                                {t('topUpCoinButtonTitle')}
                              </TopUpButton>
                            )}
                        </Box>
                      }
                    />
                  </Box>

                  {paymentType === paymentItemIndex &&
                    tokenizationData &&
                    paymentItem?.code === 'PAYMENT_CARD' && (
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          backgroundColor: isPageView
                            ? theme.palette.background.tertiery
                            : paymentType === paymentItemIndex &&
                              theme.palette.background.tertiery,
                          marginBottom: isPageView ? '12px' : 0,
                          borderRadius: '6px',
                          padding: '8px 8px',
                        }}
                      >
                        <Box
                          style={{
                            display: 'block',
                            justifyContent: 'space-between',
                            marginLeft: '22px',
                          }}
                        >
                          <FormControlLabel
                            key={paymentItemIndex}
                            value={paymentItemIndex}
                            control={<Radio />}
                            label={
                              <StyledIconRadio>
                                <Box
                                  sx={{
                                    width: '20px',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginRight: '10px',
                                  }}
                                >
                                  <PaymentCardIcon
                                    cardBrand={tokenizationData?.card_info?.brand?.toLowerCase()}
                                  />
                                </Box>
                                <Box>
                                  Bank {bankName}
                                  <StyledWrapperInfo>
                                    <StyledInfoRadio
                                      sx={{
                                        color:
                                          theme.palette.text
                                            .secondary,
                                      }}
                                    >
                                      {cardType === 'debit'
                                        ? t('key_debit_card')
                                        : t('key_credit_card')}{' '}
                                      ** {maskedCardNumber}
                                    </StyledInfoRadio>
                                  </StyledWrapperInfo>
                                </Box>
                              </StyledIconRadio>
                            }
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 20,
                              },
                            }}
                          />
                        </Box>
                        <ArrowForwardIosIcon
                          onClick={() => {
                            setModalCardForm(true)
                          }}
                          style={{
                            cursor: 'pointer',
                            color: theme.palette.text.secondary,
                            fontSize: 12,
                            marginRight: 16,
                          }}
                        />
                      </Box>
                    )}

                  {!isPageView && <StyledDivider />}
                </Box>
              </>
            )
          },
        )}
      </RadioGroup>
    </FormControl>
  )
}
