import Box from 'components/commons/Box'
import ActionButton from 'components/commons/Button/Action'
import StateImage from 'components/commons/StateImage'
import {
  ItemsLabelVa,
  ItemsLabelVaBold,
  VaDesc,
  VaTitleLabel,
  WrapperItemsPending,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import dateFormatter from 'helpers/formats/date'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import { useTranslation } from 'react-i18next'
import { StyledCoin } from '../DirectPaymentDrawerContent/styled'
import { IPaymentStatus } from 'types/payment'
import { MouseEventHandler } from 'react'
import { DATE_FORMAT_PURCHASED } from 'constants/date-formats'

export interface ISuccessPaymentModalProps {
  paymentStatus: IPaymentStatus & {
    ticket?: string
  }
  onClickActionButton?: MouseEventHandler<HTMLButtonElement>
  isOpen?: boolean
  disabled?: boolean
  buttonTitle: string
  description: string
  renderTotalPriceWithCoin?: boolean
  hideActionButton?: boolean
}

export default function SuccessPaymentModal({
  paymentStatus,
  onClickActionButton,
  disabled,
  buttonTitle,
  description,
  renderTotalPriceWithCoin = false,
  hideActionButton = false,
}: ISuccessPaymentModalProps) {
  const { t } = useTranslation()

  const renderPaymentMethodLabel = () => {
    if (paymentStatus.payment_method_code === 'TIPTIP_COIN') {
      return t('key_tiptip_coin')
    } else if (
      paymentStatus.payment_method_name === 'Other Payment Method' ||
      paymentStatus.payment_method_name ===
        'Metode Pembayaran Lain' ||
      paymentStatus.payment_method_code === 'OTHER_METHOD'
    ) {
      return (
        paymentStatus.payment_method_name +
        ` - ` +
        paymentStatus.payment_method_code
      )
    } else {
      return paymentStatus.payment_method_name
    }
  }
  return (
    <>
      <Box>
        <StateImage
          type="success-withdraw"
          sx={{
            marginBottom: '16px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
        <VaTitleLabel>
          {t('transactionVaStatusWidgetPaidTitle')}
        </VaTitleLabel>
        <VaDesc>{description}</VaDesc>
        <WrapperItemsPending>
          <ItemsLabelVa>
            {t('vaPaymentInstructionDetailWidgetTotalLabel')}
            <ItemsLabelVaBold data-testid="c-wallet-payment-status-total">
              {renderTotalPriceWithCoin ? (
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <StyledCoin />
                  {paymentStatus?.total}
                </Box>
              ) : (
                `Rp${NumberBaseFormatter(paymentStatus?.total)}`
              )}
            </ItemsLabelVaBold>
          </ItemsLabelVa>
          <ItemsLabelVa>
            {t('transactionVAStatusDetailPaidWidgetPaidAtLabel')}
            <ItemsLabelVaBold>
              {dateFormatter({
                date: paymentStatus?.paid_at,
                format: DATE_FORMAT_PURCHASED,
              })}
            </ItemsLabelVaBold>
          </ItemsLabelVa>
          <ItemsLabelVa>
            {t(
              'transactionVAStatusDetailPaidWidgetPaymentMethodNameLabel',
            )}
            <ItemsLabelVaBold data-testid="c-wallet-payment-method-label">
              {renderPaymentMethodLabel()}
            </ItemsLabelVaBold>
          </ItemsLabelVa>
        </WrapperItemsPending>
      </Box>
      {!hideActionButton && (
        <ActionButton
          id="b-wallet-click-action-success-payment"
          data-testid="b-wallet-click-action-success-payment"
          disabled={disabled}
          sx={{
            padding: '12px 24px',
            width: '100%',
          }}
          onClick={onClickActionButton}
        >
          {buttonTitle}
        </ActionButton>
      )}
    </>
  )
}
