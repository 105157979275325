import { styled } from '@mui/material'
import Box from 'components/commons/Box'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
type ICardType = {
  isDisabled?: boolean
}
export const IconVisa = styled(Box)<ICardType>(({ isDisabled }) => ({
  backgroundImage: isDisabled
    ? 'url("/img/card-payment/disabled/ic_visa.png")'
    : 'url("/img/card-payment/ic_visa.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  width: '100%',
  height: '24px',
}))
export const IconMasterCard = styled(Box)<ICardType>(
  ({ isDisabled }) => ({
    backgroundImage: isDisabled
      ? 'url("/img/card-payment/disabled/ic_mastercard.png")'
      : 'url("/img/card-payment/ic_mastercard.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    height: '24px',
  }),
)
export const IconJcb = styled(Box)<ICardType>(({ isDisabled }) => ({
  backgroundImage: isDisabled
    ? 'url("/img/card-payment/disabled/ic_jcb.png")'
    : 'url("/img/card-payment/ic_jcb.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  width: '100%',
  height: '24px',
}))

export const IconWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100px',
  marginLeft: '2px',
  marginBottom: '16px',
})
export const InputWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'stretch',
  gap: '16px',
  marginBottom: '16px',
})
interface IInputInstallment {
  disabled?: boolean
}
export const InputInstallment = styled(Box)<IInputInstallment>(
  ({ theme, disabled }) => ({
    fontSize: theme.typography.normalBold.fontSize,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 8,
    marginRight: 16,
    marginBottom: 16,
    svg: {
      marginRight: 8,
    },
    cursor: 'pointer',
    border: 'none',
    height: '44px',
    backgroundColor: theme.palette.background.tertiery,
    borderRadius: '6px',
    marginTop: '0px',
    color: disabled ? theme.palette.text.tertiery : 'none',
  }),
)

export const StyledArrow = styled(ArrowForwardIosIcon)(
  ({ theme }) => ({
    marginRight: '10px',
    fontSize: 8,
    color: theme.palette.text.tertiery,
  }),
)
