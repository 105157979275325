import Box from 'components/commons/Box'
import StateImage from 'components/commons/StateImage'
import {
  ErrorDesc,
  ErrorLabel,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import { useTranslation } from 'react-i18next'

export default function NewTabConfirmation() {
  const { t } = useTranslation()
  return (
    <Box>
      <StateImage
        sx={{
          marginBottom: '16px',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        type="guest_pending"
      />
      <ErrorLabel>{t('key_redirect_payment')}</ErrorLabel>
      <ErrorDesc>{t('key_redirect_payment_desc')}</ErrorDesc>
    </Box>
  )
}
