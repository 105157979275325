import {
  DATE_FORMAT_PURCHASED,
  OFFSET_FROM_UTC_FORMAT,
} from 'constants/date-formats'
import dateFormatter from 'helpers/formats/date'

// TO-DO: move to helpers/date-time with unit test
const handleCustomDateWithTimeZone = (date) => {
  const dateFormat = dateFormatter({
    date: date,
    format: DATE_FORMAT_PURCHASED,
  })

  const getTimeZone = dateFormatter({
    date: date,
    format: OFFSET_FROM_UTC_FORMAT,
  })

  let timeZone = ''
  switch (getTimeZone) {
    case '+0700':
      timeZone = 'WIB'
      break
    case '+0800':
      timeZone = 'WITA'
      break
    default:
      timeZone = 'WIT'
      break
  }

  return dateFormat + ' ' + timeZone
}

export default handleCustomDateWithTimeZone
