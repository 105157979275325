import { MouseEventHandler, useState } from 'react'

import { FormControl, RadioGroup } from '@mui/material'

import PaymentInfoModal from '../PaymentInfoModal'

import {
  IPaymentMethod,
  IXenditTokenizationResponse,
  PaymentMethodCode,
} from 'types/payment'
import PaymentMethodItem from 'containers/domains/Payment/PaymentMethodItem'
import ActionButton from 'components/commons/Button/Action'
import { ExpandMoreRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import isEmpty from 'helpers/is-empty'

export interface IPaymentMethodList {
  selectedPaymentMethodIndex: number
  handlePaymentTypeChange: (
    index: number,
    code?: PaymentMethodCode,
  ) => void
  topUpCoinHandler?: () => void
  isPaymentLoading: boolean
  isPageView?: boolean
  tokenizationData?: IXenditTokenizationResponse
  paymentMethodData: Array<IPaymentMethod>
  renderButtonSeeMore?: boolean
  onClickButtonSeeMore?: MouseEventHandler<HTMLButtonElement>
  useBlackIcon?: boolean
}

export default function PaymentMethodList({
  selectedPaymentMethodIndex = 0,
  handlePaymentTypeChange = () => {},
  topUpCoinHandler = () => {},
  isPaymentLoading,
  isPageView,
  tokenizationData,
  paymentMethodData,
  renderButtonSeeMore = false,
  onClickButtonSeeMore = () => {},
  useBlackIcon = true,
}: IPaymentMethodList) {
  const { t } = useTranslation()
  const [openPaymentMethodInfoModal, setOpenPaymentMethodInfoModal] =
    useState<PaymentMethodCode>(null)
  return (
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      <RadioGroup
        value={selectedPaymentMethodIndex}
        onChange={(e) =>
          handlePaymentTypeChange(parseInt(e.target.value))
        }
        data-testid="i-payment-radio-group-payment-method"
      >
        {paymentMethodData.map((paymentItem, paymentItemIndex) => {
          return (
            <>
              {openPaymentMethodInfoModal === paymentItem?.code && (
                <PaymentInfoModal
                  paymentMethod={paymentItem}
                  openInfo={openPaymentMethodInfoModal}
                  setCloseInfo={() => {
                    setOpenPaymentMethodInfoModal(null)
                  }}
                />
              )}

              <PaymentMethodItem
                useBlackIcon={useBlackIcon}
                isDisabled={isPaymentLoading}
                onClickCardItem={(event) => {
                  handlePaymentTypeChange(
                    paymentItemIndex,
                    paymentItem?.code,
                  )
                  event.preventDefault()
                }}
                paymentMethod={paymentItem}
                paymentMethodIndex={paymentItemIndex}
                selectedPaymentMethodIndex={
                  selectedPaymentMethodIndex
                }
                isPageView={isPageView}
                key={paymentItemIndex}
                onClickHelpIcon={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  if (!isPaymentLoading)
                    setOpenPaymentMethodInfoModal(paymentItem?.code)
                }}
                onClickTopUp={topUpCoinHandler}
                renderHelpIcon={!isEmpty(paymentItem.info)}
                renderWidgetCoinError={
                  paymentMethodData[selectedPaymentMethodIndex]
                    ?.code === 'TIPTIP_COIN' &&
                  paymentItem?.coin?.balance <
                    paymentItem?.coin?.total_price
                }
                tokenizationData={tokenizationData}
              />
            </>
          )
        })}
      </RadioGroup>

      {renderButtonSeeMore && (
        <ActionButton
          id="b-payment-see-more-payment-method"
          buttonVariant="ghost"
          onClick={onClickButtonSeeMore}
        >
          {t('key_see_all')}
          <ExpandMoreRounded />
        </ActionButton>
      )}
    </FormControl>
  )
}
