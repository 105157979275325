export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID
export const EXTERNAL_FB_PIXEL_ID =
  process.env.NEXT_PUBLIC_EXTERNAL_FACEBOOK_PIXEL_ID

export const pageview = () => {
  if (
    process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ENABLED === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true'
  ) {
    window.fbq('trackSingle', FB_PIXEL_ID, 'PageView')
  }
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const trackPixelEvent = (name, options = {}) => {
  if (
    process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ENABLED === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true'
  ) {
    window.fbq('trackSingle', FB_PIXEL_ID, name, options)
  }
}

export const trackExternalPixelEvent = (name, options) => {
  if (
    process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ENABLED === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true'
  ) {
    window.fbq('trackSingle', EXTERNAL_FB_PIXEL_ID, name, options)
  }
}
