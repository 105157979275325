import {
  IPaymentMethod,
  IPaymentMethodGroup,
  IPreCheckoutData,
} from 'types/payment'

/**
 * Checks if coin should be prioritized for the selected payment method.
 * This function determines whether the provided payment method corresponds to a coin-based payment method that requires priority.
 *
 * @param {string} paymentMethod - The list of payment methods from the pre-checkout process.
 * @returns {boolean} - Returns true if the coin should be prioritized as the selected payment method, otherwise false.
 */

export const isCoinPaymentDefault = (
  paymentMethod: IPaymentMethod[],
) => {
  if (paymentMethod.length) {
    // Check coin priority for selected payment method
    const paymentMethodList = paymentMethod
    const coinPayment = paymentMethodList.find(
      (item) => item.code === 'TIPTIP_COIN',
    )
    return (
      coinPayment?.coin?.balance >= coinPayment?.coin?.total_price
    )
  }
  return false
}

/**
 * Handles the index selection for topping up coins in the payment process.
 *
 * When the `preCheckoutData` is for a coin top-up and the current index is greater than the length
 * of available payment methods, this function ensures the index is set to 0.
 *
 * This workaround is necessary because the top-up coin direct payment modal renders twice,
 * causing the first render to retain the previous index. To avoid this issue, for the first render
 * of a coin top-up, the index is forced to 0.
 *
 * @param {number} selectedPaymentMethod - The current index of the selected payment method.
 * @param {Array} preCheckoutData - The list of available payment methods.
 * @returns {number} - The adjusted index (0 if the condition is met, otherwise the current index).
 */
export const getSelectedPaymentMethodIndex = (
  preCheckoutData: IPreCheckoutData,
  selectedPaymentMethod: number,
) => {
  if (
    preCheckoutData?.coins?.length &&
    preCheckoutData?.payment_method?.length <= selectedPaymentMethod
  )
    return 0

  // Default get selected payment method index
  return selectedPaymentMethod
}

/**
 * Handles errors during the card payment process.
 *
 * @param {string} errorCode - The error code returned during the payment process.
 * @param {string} [paymentSource=''] - The source of the payment error. If the error occurred during the checkout process, pass 'charge'. Default is an empty string.
 *
 * @returns {string}
 */

export const handleErrorNativePayment = (
  errorCode: string,
  paymentSource: string = '',
) => {
  if (
    (errorCode === 'INVALID_JSON_FORMAT' ||
      errorCode === 'VALIDATION_ERROR' ||
      errorCode === 'AUTHENTICATION_REQUIRED_ERROR' ||
      errorCode === 'REQUEST_FORBIDDEN_ERROR' ||
      errorCode === 'CREDIT_CARD_TOKEN_NOT_FOUND_ERROR') &&
    paymentSource !== 'charge'
  ) {
    return 'API_VALIDATION_ERROR'
  } else if (
    errorCode === 'TOKEN_ALREADY_USED_ERROR' ||
    errorCode === 'INVALID_TOKEN_ID_ERROR' ||
    errorCode === 'AUTHENTICATION_ID_MISSING_ERROR' ||
    errorCode === 'INVALID_AUTHENTICATION_ID_ERROR' ||
    errorCode === 'TOKEN_NOT_FOUND_ERROR' ||
    errorCode === 'AUTHENTICATION_NOT_FOUND_ERROR' ||
    errorCode === 'CARDHOLDER_NAME_REQUIRED' ||
    (errorCode === 'REQUEST_FORBIDDEN_ERROR' &&
      paymentSource === 'charge') ||
    (errorCode === 'API_VALIDATION_ERROR' &&
      paymentSource === 'charge') ||
    (errorCode === 'INVALID_JSON_FORMAT' &&
      paymentSource === 'charge') ||
    (errorCode === '' && paymentSource === 'charge')
  ) {
    return 'MID_NOT_FOUND_ERROR'
  } else if (
    errorCode === 'DECLINED_BY_PROCESSOR' ||
    errorCode === 'DECLINED_BY_ISSUER'
  ) {
    return 'DECLINED_BY_ISSUER'
  } else {
    if (
      paymentSource === 'native' &&
      errorCode === 'API_VALIDATION_ERROR'
    )
      return 'FAIL_CHECKOUT'
    else return errorCode
  }
}

/**
 * Flattens an array of payment method groups into a single array of payment methods.
 *
 * This function takes an array of payment method groups and combines all the payment
 * methods from each group into one flattened array.
 *
 * @param {Array<IPaymentMethodGroup>} paymentMethodGroups - An array of payment method groups.
 * Each payment method group should have a `payment_methods` property, which is an array
 * of payment methods.
 *
 * @returns {Array<IPaymentMethod>} - A flattened array containing all payment methods
 * from the provided payment method groups.
 * // Output: [{ id: 1, name: 'Credit Card' }, { id: 2, name: 'PayPal' }, { id: 3, name: 'Bank Transfer' }]
 */

export const flattenPaymentMethods = (
  paymentMethodGroups: Array<IPaymentMethodGroup>,
) => {
  return paymentMethodGroups?.flatMap(
    (paymentMethodGroup) => paymentMethodGroup.payment_methods,
  )
}
