import Box from 'components/commons/Box'
import StateImage from 'components/commons/StateImage'
import {
  ItemsLabelVa,
  ItemsLabelVaBold,
  VaDesc,
  VaTitleLabel,
  WrapperItemsPending,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import dateFormatter from 'helpers/formats/date'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import { useTranslation } from 'react-i18next'

interface IPendingStateProps {
  vaStatus: {
    invoice_id: string
    payment_method_code: string
    status: string
    paid_at: string
    checkout_at: string
    payment_method_name: string
    total: number
  }
  itemType: string
}
export default function PendingStateContent({
  vaStatus,
  itemType,
}: IPendingStateProps) {
  const { t } = useTranslation()

  return (
    <Box>
      <StateImage
        type="pending-withdraw"
        sx={{
          marginBottom: '16px',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <VaTitleLabel>
        {t('transactionHistoryPendingStatus')}
      </VaTitleLabel>
      <VaDesc>
        {itemType === 'coin'
          ? t('key_please_complete_the_payment_before')
          : t('key_please_complete_the_payment')}
      </VaDesc>
      <WrapperItemsPending>
        <ItemsLabelVa>
          {t('vaPaymentInstructionDetailWidgetTotalLabel')}
          <ItemsLabelVaBold>
            Rp{NumberBaseFormatter(vaStatus?.total)}
          </ItemsLabelVaBold>
        </ItemsLabelVa>
        <ItemsLabelVa>
          {t('transactionVAStatusDetailPendingWidgetCheckoutAtLabel')}
          <ItemsLabelVaBold>
            {dateFormatter({
              date: vaStatus?.checkout_at,
              format: 'DD MMM YYYY, HH:mm',
            })}
          </ItemsLabelVaBold>
        </ItemsLabelVa>
      </WrapperItemsPending>
    </Box>
  )
}
