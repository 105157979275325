import { getIdToken } from 'helpers/auth'
import apiClient from 'helpers/api-client'
import { generateShortUuid } from 'helpers/generate/uuid'
import { getLatitudeLongitude } from 'helpers/navigator'
import { getSessionStorage } from 'helpers/session-storage'
import { setCanonicalUrl } from 'helpers/url'
import { getPartnerToken } from 'helpers/partner'
import apiConfig from 'configs/api'
import isEmpty from 'helpers/is-empty'

interface ICartPayload {
  product_uid: string | string[]
  qty?: number
  idempotentKey: string
}
//deprecated on event
export const getCartValues = async (payload: ICartPayload) => {
  const response = await apiClient({
    url: '/order/v2/cart',
    method: 'POST',
    token: getIdToken(),
    data: {
      products: [
        { product_uid: payload.product_uid, qty: payload.qty || 1 },
      ],
    },
    headers: { 'Idempotent-Key': payload.idempotentKey },
  })
  return response.data.data
}

interface ICheckoutPayload {
  invoice_id: string
  token: string
  product_id: string
  accesstrade_click_id: string
  voucher_code?: string
  promoter_token: string
  user_mask_id?: string
  additional_info?: any
  payment_method?: string
  idempotentKey: string
  selected_seats?: string[]
  send_to_additional_emails?: boolean
}

export const getCheckoutValues = async (
  payload: ICheckoutPayload,
) => {
  const response = await apiClient({
    url: '/order/v1/checkout',
    method: 'POST',
    token: getIdToken(),
    data: {
      invoice_id: payload.invoice_id,
      token: payload.token,
      product_id: payload.product_id,
      accesstrade_click_id: payload.accesstrade_click_id,
      voucher_code: payload.voucher_code,
      promoter_token: payload.promoter_token,
      user_mask_id: payload.user_mask_id,
      additional_info: payload.additional_info,
      selected_seats: payload.selected_seats,
      payment_method: payload.payment_method,
      send_to_additional_emails:
        payload.send_to_additional_emails || false,
    },
    headers: { 'Idempotent-Key': payload.idempotentKey },
  })
  return response.data.data
}

interface ICheckoutInstruction {
  invoice_id: string
  payment_method: string
  idempotentKey: string
}
export const getCheckoutInstruction = async (
  payload: ICheckoutInstruction,
) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/payment/v1/directpay/va/detail`
      : `/payment/guest/v1/directpay/va/detail`,
    method: 'GET',
    token: getIdToken(),
    headers: { 'Idempotent-Key': payload.idempotentKey },
    params: {
      invoice_id: payload.invoice_id,
      payment_method_code: payload.payment_method,
    },
  })

  return response.data.data
}

type ICheckoutInstructionV2 = Omit<
  ICheckoutInstruction,
  'payment_method'
>
export const getCheckoutInstructionV2 = async (
  payload: ICheckoutInstructionV2,
) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/payment/v1/directpay/instruction`
      : `/payment/guest/v1/directpay/instruction`,
    method: 'GET',
    token: getIdToken(),
    headers: { 'Idempotent-Key': payload.idempotentKey },
    params: {
      invoice_id: payload.invoice_id,
    },
  })

  return response.data.data
}
interface IPaymentHistoryPayload {
  payment_status?: string
  product_type?: number
  cursor?: string
}

export const getPaymentHistory = async (
  payload: IPaymentHistoryPayload,
) => {
  //request from BE for cleaner on BE side
  const paramHandler = () => {
    if (payload.payment_status !== '' && payload.product_type !== 0) {
      return {
        payment_status: payload.payment_status,
        product_type: payload.product_type,
        cursor: payload.cursor,
      }
    } else if (
      payload.payment_status === '' &&
      payload.product_type !== 0
    ) {
      return {
        product_type: payload.product_type,
        cursor: payload.cursor,
      }
    } else if (
      payload.payment_status !== '' &&
      payload.product_type === 0
    ) {
      return {
        payment_status: payload.payment_status,
        cursor: payload.cursor,
      }
    } else {
      return {
        cursor: payload.cursor,
      }
    }
  }
  const response = await apiClient({
    url: `/payment/v1/directpay/history`,
    method: 'GET',
    token: getIdToken(),
    params: paramHandler(),
  })
  return response.data
}

interface IVaStatus {
  invoiceId: string
  paymentMethod: string
  idempotentKey: string
}

export const getPaymentStatus = async (payload: IVaStatus) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/payment/v1/directpay/va/check-status`
      : `/payment/guest/v1/directpay/va/check-status`,
    method: 'GET',
    token: getIdToken(),
    headers: { 'Idempotent-Key': payload.idempotentKey },
    params: {
      invoice_id: payload.invoiceId,
      payment_method_code: payload.paymentMethod,
    },
  })
  return response.data.data
}

export const postDirectPaymentStatus = async (
  payload: Omit<IVaStatus, 'paymentMethod'>,
) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/payment/v1/directpay/check-status`
      : `/payment/guest/v1/directpay/check-status`,
    method: 'POST',
    token: getIdToken(),
    headers: { 'Idempotent-Key': payload.idempotentKey },
    data: {
      invoice_id: payload.invoiceId,
    },
  })
  return response.data.data
}

export const paymentConfirmation = async ({
  invoice,
}: {
  invoice: string
}) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/payment/test/v1/confirm`
      : `/payment/guest/test/v1/confirm`,
    method: 'POST',
    token: getIdToken(),
    headers: { 'Idempotent-Key': generateShortUuid() },
    data: {
      invoice,
      req_type: 'payment',
    },
  })

  return response.data.data
}

export const paymentConfirmationV2 = async ({
  invoice,
}: {
  invoice: string
}) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/payment/test/v2/confirm`
      : `/payment/guest/test/v2/confirm`,
    method: 'POST',
    token: getIdToken(),
    headers: { 'Idempotent-Key': generateShortUuid() },
    data: {
      invoice,
      req_type: 'payment',
    },
  })

  return response.data.data
}
export interface IProductsProps {
  product_uid: string | string[]
  qty?: number
  head_source: string
  promoter_token?: string
  additional_info?: Array<{
    full_name: string
    email: string
    phone_number: string
  }>
  selected_seats?: Array<string>
  send_to_additional_emails?: boolean
  tickets?: Array<{
    ticket_schedule_uid: string
    qty: number
  }>
}
export interface IPaymentCheckoutBody {
  products?: Array<IProductsProps>
  payment_method: string
  checkout_source: string
  user_mask_id?: string
  voucher_code?: string
  idempotentKey: string
}

export const checkoutPayment = async ({
  products,
  payment_method,
  checkout_source,
  user_mask_id,
  voucher_code,
  idempotentKey,
}: IPaymentCheckoutBody) => {
  const location = await getLatitudeLongitude()
  const response = await apiClient({
    url: getIdToken()
      ? `/order/v2/checkout`
      : `/order/guest/v1/checkout`,
    method: 'POST',
    token: getIdToken(),
    data: {
      products: products,
      payment_method: payment_method,
      checkout_source: checkout_source,
      user_mask_id: user_mask_id,
      voucher_code: voucher_code,
      redirection_link:
        getIdToken() &&
        (checkout_source.toLocaleLowerCase() === 'cart'
          ? `${window.location.origin}/transaction-history`
          : setCanonicalUrl()),
    },
    headers: {
      'Idempotent-Key': idempotentKey,
      Location:
        `${location['latitude']}, ${location['longitude']}` ||
        `${getSessionStorage('metaData')['latitude']},${
          getSessionStorage('metaData')['longitude']
        }` ||
        '',
      'Device-Real': false,
      'Device-Rooted': false,
    },
    timeout: 30000,
  })
  return response.data.data
}

export interface IPaymentEventCheckoutBody {
  book_session_id: string
  payment_method: string
  checkout_source: string
  promoter_token: string
  voucher_code: string
  idempotentKey: string
  payment_card_request?: {
    bin?: string
    token_id: string
    authentication_id: string
    installment: {
      count: number
      interval: string
      acquirer?: string
    }
  }
  redirection_link?: string
  e_wallet_request?: {
    phone_number: string
  }
}

/**
 * @deprecated
 * This endpoint event checkout (v1) is deprecated and will be removed in a future version.
 * Please migrate to the v2 endpoint for improved functionality and support.
 *
 * @endpoint {string} /order/v1/event/checkout
 * @description This endpoint retrieves the payment methods available in the system using version 1.
 *
 * @note For new implementations, use the v2 endpoint: eventCheckoutPaymentV2
 */
export const eventCheckoutPayment = async ({
  book_session_id,
  payment_method,
  checkout_source,
  promoter_token,
  voucher_code,
  idempotentKey,
  payment_card_request,
  redirection_link,
}: IPaymentEventCheckoutBody) => {
  let location = ''
  if (!getPartnerToken()) {
    const locationResponse = await getLatitudeLongitude()
    if (locationResponse) {
      location = `${locationResponse['latitude']}, ${locationResponse['longitude']}`
    }
  }

  const metaData = getSessionStorage('metaData')

  if (
    !location &&
    !isEmpty(metaData) &&
    metaData?.longitude &&
    metaData?.latitude
  ) {
    const metaLongitude = metaData.longitude?.toString() || ''
    const metaLatitude = metaData.latitude?.toString() || ''
    location = `${metaLatitude},${metaLongitude}`
  }

  const response = await apiClient({
    url: getIdToken()
      ? `/order/v1/event/checkout`
      : `/order/guest/v1/event/checkout`,
    method: 'POST',
    token: getIdToken(),
    data: {
      book_session_id,
      payment_method,
      promoter_token,
      voucher_code: voucher_code,
      redirection_link: redirection_link
        ? redirection_link
        : getIdToken() &&
          (checkout_source.toLocaleLowerCase() === 'cart'
            ? `${window.location.origin}/transaction-history`
            : setCanonicalUrl()),
      payment_card_request: {
        bin: payment_card_request?.bin,
        token_id: payment_card_request?.token_id,
        authentication_id: payment_card_request?.authentication_id,
        installment: {
          count: payment_card_request?.installment.count,
          interval: 'month',
        },
      },
    },
    headers: {
      'Idempotent-Key': idempotentKey,
      Location: location,
      'Device-Real': false,
      'Device-Rooted': false,
      ...(getPartnerToken() && {
        'Partner-Token': getPartnerToken(),
        Channel: apiConfig.channelPartner,
      }),
    },
    timeout: 30000,
  })
  return response.data.data
}

export const eventCheckoutPaymentV2 = async ({
  book_session_id,
  payment_method,
  checkout_source,
  promoter_token,
  voucher_code,
  idempotentKey,
  payment_card_request,
  redirection_link,
  e_wallet_request,
}: IPaymentEventCheckoutBody) => {
  let location = ''
  if (!getPartnerToken()) {
    const locationResponse = await getLatitudeLongitude()
    if (locationResponse) {
      location = `${locationResponse['latitude']}, ${locationResponse['longitude']}`
    }
  }

  const metaData = getSessionStorage('metaData')

  if (
    !location &&
    !isEmpty(metaData) &&
    metaData?.longitude &&
    metaData?.latitude
  ) {
    const metaLongitude = metaData.longitude?.toString() || ''
    const metaLatitude = metaData.latitude?.toString() || ''
    location = `${metaLatitude},${metaLongitude}`
  }

  const response = await apiClient({
    url: getIdToken()
      ? `/order/v2/event/checkout`
      : `/order/guest/v2/event/checkout`,
    method: 'POST',
    token: getIdToken(),
    data: {
      book_session_id,
      payment_method_code: payment_method,
      promoter_token,
      voucher_code: voucher_code,
      redirection_link: redirection_link
        ? redirection_link
        : getIdToken() &&
          (checkout_source.toLocaleLowerCase() === 'cart'
            ? `${window.location.origin}/transaction-history`
            : setCanonicalUrl()),
      payment_card_request: payment_card_request
        ? {
            bin: payment_card_request.bin,
            token_id: payment_card_request.token_id,
            authentication_id: payment_card_request.authentication_id,
            installment: payment_card_request
              ? {
                  count: payment_card_request.installment.count,
                  interval: 'month',
                  acquirer: payment_card_request.installment.acquirer,
                }
              : {},
          }
        : {},
      e_wallet_request: e_wallet_request || {},
    },
    headers: {
      'Idempotent-Key': idempotentKey,
      Location: location,
      'Device-Real': false,
      'Device-Rooted': false,
      ...(getPartnerToken() && {
        'Partner-Token': getPartnerToken(),
        Channel: apiConfig.channelPartner,
      }),
    },
    timeout: 30000,
  })
  return response.data.data
}

interface IEventPreCheckoutBody {
  book_session_id: string
  idempotentKey: string
}

/**
 * @deprecated
 * This endpoint event pre-checkout (v1) is deprecated and will be removed in a future version.
 * Please migrate to the v2 endpoint for improved functionality and support.
 *
 * @endpoint {string} /order/v1/event/pre-checkout
 * @description This endpoint retrieves the payment methods available in the system using version 1.
 *
 * @note For new implementations, use the v2 endpoint: eventPreCheckoutPaymentV2
 */
export const eventPreCheckoutPayment = async ({
  book_session_id,
  idempotentKey,
}: IEventPreCheckoutBody) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/order/v1/event/pre-checkout`
      : `/order/guest/v1/event/pre-checkout`,
    method: 'POST',
    token: getIdToken(),
    data: { book_session_id },
    headers: {
      'Idempotent-Key': idempotentKey,
      ...(getPartnerToken() && {
        'Partner-Token': getPartnerToken(),
        Channel: apiConfig.channelPartner,
      }),
    },
    timeout: 30000,
  })
  return response.data.data
}

export const eventPreCheckoutPaymentV2 = async ({
  book_session_id,
  idempotentKey,
}: IEventPreCheckoutBody) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/order/v2/event/pre-checkout`
      : `/order/guest/v2/event/pre-checkout`,
    method: 'POST',
    token: getIdToken(),
    data: { book_session_id },
    headers: {
      'Idempotent-Key': idempotentKey,
      ...(getPartnerToken() && {
        'Partner-Token': getPartnerToken(),
        Channel: apiConfig.channelPartner,
      }),
    },
    timeout: 30000,
  })
  return response.data.data
}

interface IPreCheckoutBody {
  products?: Array<IProductsProps>
  checkout_source: string
  user_mask_id?: string
  idempotentKey: string
}

export const preCheckoutPayment = async ({
  products,
  checkout_source,
  idempotentKey,
}: IPreCheckoutBody) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/order/v2/pre-checkout`
      : `/order/guest/v2/pre-checkout`,
    method: 'POST',
    token: getIdToken(),
    data: { products: products, checkout_source: checkout_source },
    headers: { 'Idempotent-Key': idempotentKey },
    timeout: 30000,
  })
  return response.data.data
}
interface ICancelPayment {
  invoice_id: string
  idempotentKey?: string
}
export const cancelPayment = async (payload: ICancelPayment) => {
  const response = await apiClient({
    url: getIdToken()
      ? `/payment/v1/directpay/cancel`
      : `/payment/guest/v1/directpay/cancel`,
    method: 'POST',
    token: getIdToken(),
    data: { invoice_id: payload?.invoice_id },
    headers: {
      'Idempotent-Key': payload.idempotentKey || generateShortUuid(),
      ...(getPartnerToken() && {
        'Partner-Token': getPartnerToken(),
        Channel: apiConfig.channelPartner,
      }),
    },
  })
  return response.data.message
}

export const topUpCoinPayment = async ({ coins, idempotentKey }) => {
  try {
    const response = await apiClient({
      url: `/payment/v1/topup`,
      method: 'POST',
      token: getIdToken(),
      headers: { 'Idempotent-Key': idempotentKey },
      data: {
        coins: coins,
      },
    })
    return response.data.data
  } catch (error) {
    throw error
  }
}

interface IPostTopupRequest {
  idempotentKey: string
  payment_id: string
  payment_method_code: string
}
export const postTopupRequest = async ({
  idempotentKey,
  payment_id,
  payment_method_code,
}: IPostTopupRequest) => {
  try {
    const response = await apiClient({
      url: `/payment/v1/topup/invoice`,
      method: 'POST',
      token: getIdToken(),
      headers: { 'Idempotent-Key': idempotentKey },
      data: {
        payment_id: payment_id,
        payment_method_code: payment_method_code,
      },
    })
    return response.data.data
  } catch (error) {
    throw error
  }
}

interface IInstallmentPlanOption {
  product_uid: string
  amount: number
  bin: number
  idempotentKey: string
}
export const getInstallmentPlanOption = async (
  payload: IInstallmentPlanOption,
) => {
  const response = await apiClient({
    url: 'payment/guest/v1/directpay/installment/check',
    method: 'GET',
    headers: { 'Idempotent-Key': payload.idempotentKey },
    params: {
      product_uid: payload.product_uid,
      amount: payload.amount,
      bin: payload.bin,
    },
  })
  return response.data.data
}
