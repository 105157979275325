import { useRef } from 'react'
import appConfig from 'configs/app'
import htmlParse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import { IPaymentInstructionList } from 'types/payment'

import StateImage from 'components/commons/StateImage'
import Barcode from 'components/domains/Event/Barcode'
import ActionButton from 'components/commons/Button/Action'
import {
  BodyQrWrapper,
  InstructionsWrapper,
  QrWrapper,
  StyledBoldBlackTitle,
  StyledNormaBoldlTitle,
  StyledNormalTitle,
} from 'containers/domains/Payment/PaymentInstructions/styled'

import isEmpty from 'helpers/is-empty'
import { handleDownloadElementAsImage } from 'helpers/html2canvas'

export default function PaymentInstructionsQrBody({
  showQRCode = false,
  showTitle = false,
  paymentInstructionList,
}: {
  showQRCode?: boolean
  showTitle?: boolean
  paymentInstructionList: IPaymentInstructionList
}) {
  const qrImageRef = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()

  const TitleInstructionsHandler = () => {
    switch (paymentInstructionList?.payment_method_code) {
      case 'QR':
        return (
          <StyledBoldBlackTitle>
            {t('key_accept_qris')}
          </StyledBoldBlackTitle>
        )
      case 'OVO':
        return (
          <StyledNormalTitle>
            {t('key_please_check_ovo_app_part_1')}
            <StyledNormaBoldlTitle>
              {` ${paymentInstructionList?.e_wallet_detail?.phone_number} `}
            </StyledNormaBoldlTitle>
            {t('key_please_check_ovo_app_part_2')}
          </StyledNormalTitle>
        )
    }
  }

  return (
    <>
      {showTitle && <TitleInstructionsHandler />}
      {showQRCode && (
        <BodyQrWrapper>
          <StateImage
            sx={{
              display: 'flex',
              margin: 'auto',
              height: '37px',
            }}
            src={`${appConfig.assetBaseUrl}/webp/qris.webp`}
          />
          <QrWrapper ref={qrImageRef}>
            {paymentInstructionList?.qr_detail?.qr_string && (
              <Barcode
                code={paymentInstructionList?.qr_detail?.qr_string?.toUpperCase()}
              />
            )}
          </QrWrapper>
          <ActionButton
            id="b-payment-download-qr"
            sx={{
              padding: '0px 44px !important',
              display: 'block',
              margin: 'auto',
            }}
            buttonVariant={'outlined'}
            disabled={isEmpty(
              paymentInstructionList?.qr_detail?.qr_string,
            )}
            onClick={() => handleDownloadElementAsImage(qrImageRef)}
          >
            {t('key_download_qris')}
          </ActionButton>
        </BodyQrWrapper>
      )}
      <InstructionsWrapper>
        {!isEmpty(paymentInstructionList?.instructions?.[0]?.step) &&
          htmlParse(
            paymentInstructionList?.instructions?.[0]?.step.toString(),
          )}
      </InstructionsWrapper>
    </>
  )
}
