import Box from 'components/commons/Box'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import StateImage from 'components/commons/StateImage'
import {
  ItemsLabelVa,
  ItemsLabelVaBold,
  StyledInfoIcon,
  VaTitleLabel,
  WrapperItemsPending,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import { StyledCoin } from '../DirectPaymentDrawerContent/styled'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import ActionButton from 'components/commons/Button/Action'
import { useTranslation } from 'react-i18next'
import {
  LabelWrapperInfo,
  StyledInfoLabel,
} from 'containers/domains/TransactionHistory/TransactionCard/styled'
interface ISuccessTopup {
  coin_info: {
    coin_balance: number
    top_up: number
  }
  handleClose: any
  topUpCoinStatus: string
}
export default function SuccessTopupCoin({
  coin_info,
  handleClose,
  topUpCoinStatus,
}: ISuccessTopup) {
  const { t } = useTranslation()
  return (
    <>
      <Box>
        <StateImage
          type="complete-withdraw"
          sx={{
            marginBottom: '24px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
        <VaTitleLabel
          style={{
            display: 'block',
            textAlign: 'center',
            marginBottom: '14px',
          }}
        >
          {t('key_coin_refill_successful')}{' '}
          <CheckCircleIcon
            sx={{ fontSize: '18px', color: '#51CE83' }}
          />
        </VaTitleLabel>
        <WrapperItemsPending
          style={{
            backgroundColor: 'none',
          }}
        >
          <ItemsLabelVa>
            {t('key_status')}
            <ItemsLabelVaBold>
              {t('key_success_payment')}
            </ItemsLabelVaBold>
          </ItemsLabelVa>
          <ItemsLabelVa>
            {t('key_total_top_up')}
            <ItemsLabelVaBold>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <StyledCoin /> {NumberBaseFormatter(coin_info.top_up)}
              </Box>
            </ItemsLabelVaBold>
          </ItemsLabelVa>
          <ItemsLabelVa>
            {t('successPurchaseWithCoinBottomSheetWidgetPrevBalance')}
            <ItemsLabelVaBold>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <StyledCoin />
                {NumberBaseFormatter(coin_info.coin_balance)}
              </Box>
            </ItemsLabelVaBold>
          </ItemsLabelVa>
        </WrapperItemsPending>
        {topUpCoinStatus === 'start' && (
          <LabelWrapperInfo>
            <StyledInfoIcon />
            <StyledInfoLabel>
              {t('key_please_click_next_then_complete')}
            </StyledInfoLabel>
          </LabelWrapperInfo>
        )}
      </Box>
      <ActionButton
        sx={{
          padding: '12px 24px',
          width: '100%',
        }}
        id="b-wallet-submit-top-up-continue"
        onClick={handleClose}
      >
        {topUpCoinStatus === 'start'
          ? t('nextButtonForm')
          : t('close')}
      </ActionButton>
    </>
  )
}
