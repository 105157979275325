/* eslint-disable no-unused-vars */
export const libraryConst = {
  defaultPage: 1,
  defaultLimit: 10,
  defaultActiveTabIndex: 0,
  map_tab_library: {
    0: 'event',
    1: 'content',
    2: 'booking',
    3: 'subscription',
  },
  map_filter_tab_booking: {
    0: 'LIVE_NOW',
    1: 'UPCOMING',
    2: 'PAST',
  },
  map_tab_booking_status: {
    0: 'live',
    1: 'upcoming',
    2: 'past',
  },
  map_filter_tab_event: {
    0: 'ALL',
    1: 'UPCOMING',
    2: 'PAST',
  },
  map_tab_event_status: {
    0: 'all',
    1: 'upcoming',
    2: 'past',
  },
  map_content_type_by_id: {
    5: 'video',
    6: 'audio',
    7: 'image',
    8: 'document',
    9: 'bundling',
  },
}

export const contentConst = {
  typeId: {
    session: 1,
    digital_content: 2,
    live_session: 3,
    oneOnOne_session: 4,
    video: 5,
    audio: 6,
    image: 7,
    document: 8,
    bundling: 9,
    asset: 10,
  },
  1: {
    label: 'Session',
  },
  2: {
    label: 'Digital Content',
  },
  3: {
    label: 'Live Session',
  },
  4: {
    label: '1on1 Session',
  },
  5: {
    label: 'Video',
  },
  6: {
    label: 'Audio',
  },
  7: {
    label: 'Image',
  },
  8: {
    label: 'Document',
  },
  9: {
    label: 'Bundling',
  },
  10: {
    label: 'Digital Asset',
  },
}

export enum Tabs {
  DigitalContent = 'digitalContent',
  Shop = 'shop',
  GroupSession = 'groupSession',
  PrivateSession = 'privateSession',
  OfflineSession = 'offlineSession',
  Event = 'event',
}

interface DataTag {
  eventName: string
  stateSubName: string
}

export const dataTagMaps: Record<Tabs, DataTag> = {
  [Tabs.DigitalContent]: {
    eventName: 'select_digital_content_tab',
    stateSubName: 'digital_content',
  },
  [Tabs.OfflineSession]: {
    eventName: 'select_offline_content_tab',
    stateSubName: 'offline_sssion',
  },
  [Tabs.PrivateSession]: {
    eventName: 'select_1_on_1_session_tab',
    stateSubName: '1_on_1_session',
  },
  [Tabs.Event]: {
    eventName: 'select_event_tab',
    stateSubName: 'event',
  },
  [Tabs.GroupSession]: {
    eventName: 'select_group_session_tab',
    stateSubName: 'group_session',
  },
  [Tabs.Shop]: {
    eventName: 'select_shop_tab',
    stateSubName: 'shop',
  },
}

export const verificationMethodConst = {
  0: 'WA',
  1: 'SMS',
}
export const bitmovinQualityLabels = {
  360: '360p',
  480: '480p',
  720: '720p',
  1080: '1080p HD',
}

export const socmedLinks = {
  twitter: 'https://twitter.com/tiptip_id',
  youtube: 'https://www.youtube.com/@tiptip_id',
  instagram: 'https://www.instagram.com/tiptipid/',
  linkedin: 'https://www.linkedin.com/company/tiptipnetwork',
  facebook: 'https://www.facebook.com/profile.php?id=100086320579984',
  tiktok: 'https://www.tiktok.com/@tiptip_id',
  pinterest: 'https://id.pinterest.com/tiptip_id/',
  email: 'helpcare@tiptip.id',
  whatsapp: '0811 - 1911 - 1888',
}

export const downloadLinks = {
  googlePlay:
    'https://play.google.com/store/apps/details?id=tv.tiptip.app.mobile',
  appStore: 'https://apps.apple.com/us/app/tiptip/id1615771123',
}

export const companyInfo = {
  address:
    'Go Work Central Park, Central Park Mall, Level LG, Unit L109-114, Jl. Letjen S. Parman, Daerah Khusus Ibukota Jakarta 11470',
  business: '021-5088-2936',
}

export const priceType = [
  {
    id: 1,
    name: 'sessionDetailFreeSessionLabel',
    isChecked: false,
  },
  { id: 2, name: 'filterSessionPremium', isChecked: false },
]

export const eventStatusFilter = [
  { label: 'eventLabelComingSoon', value: 'upcoming' },
  { label: 'eventLabelEventEnded', value: 'event_ended' },
]

export const roleType = [
  { id: 1, name: 'personal', isChecked: false, value: 'PERSONAL' },
  { id: 2, name: 'creator', isChecked: false, value: 'CREATOR' },
  { id: 3, name: 'business', isChecked: false, value: 'BUSINESS' },
]

export type productType =
  | 'event'
  | 'content'
  | 'session'
  | 'community'

export type sessionStatus =
  | 'upcoming'
  | 'canceled'
  | 'live'
  | 'completed'

export enum TabsHome {
  Event = 'event',
  Content = 'content',
  Session = 'session',
  Community = 'community',
}

export const defaultLocation = {
  id: null,
  name: '',
  thumbnail_image: '',
}

export const otpRetryIn = 60

export enum ThemeKeys {
  DEFAULT = 'DEFAULT',
  BRI = 'BRI',
}
