import React, { useEffect, useState, useRef } from 'react'
import { useSupportedCountry } from 'hooks/commons/useSupportedCountry'
import { useRouter } from 'next/router'
import Flag from 'react-country-flag'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from 'components/commons/Typography'
import Stack from 'components/commons/Stack'
import { useAuthStore } from 'stores/domains/User'
import SearchIcon from '@mui/icons-material/Search'
import {
  debounce,
  InputAdornment,
  SxProps,
  useMediaQuery,
} from '@mui/material'
import theme from 'theme'
import BottomDrawer from 'components/commons/BottomDrawer'
import Grid from 'components/commons/Grid'
import StateImage from 'components/commons/StateImage'
import Attention from 'components/commons/Attention'
import { getName, getNames, getCode } from 'country-list'
import { useSupportedCountryStore } from 'stores/commons'
import { useTranslation } from 'react-i18next'
import {
  StyledStack,
  StyledSelectContainer,
  StyledDesktopContainer,
  StyledTextField,
  StyledCountryWrapper,
} from 'components/domains/User/SelectCountryCodes/styled'

interface ISelectCountryCodes {
  onChanged?: (value: {
    areaCode?: string
    countryCode?: string
  }) => void
  value?: {
    areaCode?: string
    countryCode?: string
  }
  readOnly?: boolean
  isHideFlag?: boolean
  sx?: SxProps
  position?: 'absolute' | 'fixed'
}

const CountrySelect = ({
  onChanged,
  value = {
    areaCode: '+62',
    countryCode: 'ID',
  },
  readOnly = false,
  isHideFlag = false,
  sx,
  position = 'absolute',
}: ISelectCountryCodes) => {
  const { t } = useTranslation()
  const { isReady } = useRouter()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const [countriesData, setCountriesData] = useState([])

  const [searchTerm, setSearchTerm] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const drawerRef = useRef(null)

  const { setCountryData, countryData } = useAuthStore((state) => ({
    setCountryData: state.setCountryData,
    countryData: state.countryData,
  }))

  const { isError } = useSupportedCountryStore((state) => ({
    isError: state.isError,
  }))

  const { refetch: getSupportedCountry, data } = useSupportedCountry()

  const handleSelect = (country) => {
    setIsOpen(false)
    setCountryData(country)
    if (onChanged) {
      onChanged({
        areaCode: country.dial_code,
        countryCode: country.country_code,
      })
    }
  }

  const filteredOptions = countriesData?.filter(
    (country) =>
      country.dial_code.includes(searchTerm.toLowerCase()) ||
      country?.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      country.country_code
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
  )

  const getNewArrayMapping = () => {
    const names = getNames()
    const countries = names.map((name) => ({
      name,
      country_code: getCode(name),
    }))
    const newArray = data.map((item1) => {
      const item2 = countries.find(
        (item2) => item2.country_code === item1.country_code,
      )
      return { ...item1, ...item2 }
    })
    setCountriesData(newArray)
  }

  const handleCloseAreaCodeDrawer = () => {
    setSearchTerm('')
    setIsOpen(false)
  }

  const renderCountryList = () => {
    return (
      <>
        {filteredOptions.map((item) => (
          <StyledCountryWrapper
            key={`country-code-container-${item.country_code}`}
            onClick={() => handleSelect(item)}
            direction={`row`}
          >
            <Stack direction={'row'}>
              <Flag
                countryCode={item.country_code}
                svg
                style={{
                  width: 24,
                  marginRight: 10,
                  marginTop: isDesktop ? 2.5 : 5,
                }}
              />
              <Typography>{getName(item.country_code)}</Typography>
            </Stack>
            <Typography>{item.dial_code}</Typography>
          </StyledCountryWrapper>
        ))}
      </>
    )
  }

  const renderEmpty = () => {
    return (
      <Attention
        title={t('countryCodeNotFound')}
        desc={t('tryOtherKeyword')}
        sx={{
          '& p': {
            fontWeight: `800 !important`,
            fontSize: `18px !important`,
          },
        }}
      >
        <StateImage type="error" />
      </Attention>
    )
  }

  const renderError = () => {
    return (
      <Attention
        padding="20px"
        title={t('internalErrorTitle')}
        desc={t('pleaseTryAgain')}
        textBtnFirst={t('tryAgain')}
        variantBtnFirst={'filled'}
        handleRedirectBtnFirst={getSupportedCountry}
        sx={{
          '& p': {
            fontWeight: `800 !important`,
            fontSize: `18px !important`,
          },
        }}
      >
        <StateImage type="error" />
      </Attention>
    )
  }

  const renderContent = () => {
    return (
      <Grid
        id="c-users-phone-drawer-grid-container"
        container
        spacing={2}
        gap="16px"
        sx={{
          marginLeft: '0px',
          width: `100%`,
          marginTop: `10px`,
        }}
        ref={drawerRef}
      >
        <StyledTextField
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          variant="outlined"
          placeholder={t('countryListPickerTextfieldTitle')}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ width: 24, height: 24 }}
              >
                <SearchIcon color={`tertiery`} />
              </InputAdornment>
            ),
          }}
        />
        <StyledStack
          spacing={1}
          sx={{
            maxHeight: isDesktop && '220px',
            height: !isDesktop && '78vh',
          }}
        >
          {!isError
            ? filteredOptions.length > 0
              ? renderCountryList()
              : renderEmpty()
            : renderError()}
        </StyledStack>
      </Grid>
    )
  }

  const renderMobileView = () => {
    return (
      <BottomDrawer
        title="Cari Kode Negaramu"
        content={renderContent()}
        showDrawer={isOpen}
        onCloseAction={debounce(handleCloseAreaCodeDrawer, 250)}
        hideButtonRequest
        hideCancelButton
        disablePadding
        sx={{
          top: '56px',
          '>div': {
            gap: '8px',
          },
        }}
      />
    )
  }

  const renderDesktopView = () => {
    return (
      <StyledDesktopContainer sx={{ position: position }}>
        {renderContent()}
      </StyledDesktopContainer>
    )
  }

  useEffect(() => {
    if (isReady) {
      getSupportedCountry()
      onChanged(value)
    }
  }, [isReady])

  useEffect(() => {
    if (isReady && data?.length > 0) {
      getNewArrayMapping()
    }
  }, [isReady, data])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target)
      ) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  return (
    <>
      <input type="hidden" value={value.areaCode} />
      <Stack
        data-testid="country-select-button"
        onClick={() => {
          setSearchTerm('')
          !readOnly && setIsOpen(!isOpen)
        }}
      >
        <StyledSelectContainer direction={'row'} sx={sx}>
          {!isHideFlag && (
            <Flag
              countryCode={countryData.country_code}
              svg
              style={{
                width: 16,
                marginRight: 3,
              }}
            />
          )}
          {value ? value.areaCode : countryData.dial_code}
          <ExpandMoreIcon />
        </StyledSelectContainer>
      </Stack>
      {isOpen && (
        <>{isDesktop ? renderDesktopView() : renderMobileView()}</>
      )}
    </>
  )
}

export default CountrySelect
