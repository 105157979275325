import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useFeatureFlags } from 'helpers/feature-flag'
import isEmpty from 'helpers/is-empty'
import {
  eventVoucherCheck,
  eventVoucherCheckV2,
  voucherCheck,
  voucherInbox,
} from 'services/campaign'
import { IProductsProps } from 'services/payment'
import { usePaymentStore } from 'stores/domains/Payment'
import { IVoucherCard, IVoucherInboxBody } from 'types/campaign'
import { IPreCheckoutData, PaymentMethodCode } from 'types/payment'

export const useVoucherInbox = (payload: IVoucherInboxBody) => {
  const {
    data: voucherInboxData,
    isFetching: isVoucherInboxFetching,
    error: errorVoucherInbox,
    refetch: fetchVoucherInbox,
    isError: isErrorVoucherInbox,
  } = useQuery<IVoucherCard[], AxiosError>({
    queryKey: ['product-voucher-inbox'],
    queryFn: async () => {
      return await voucherInbox({
        products: payload.products,
        payment_method: payload.payment_method,
        idempotentKey: payload.idempotentKey,
        checkout_source: payload.checkout_source,
      })
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: true,
  })

  return {
    voucherInboxData,
    isVoucherInboxFetching,
    errorVoucherInbox,
    fetchVoucherInbox,
    isErrorVoucherInbox,
  }
}

interface ICheckVoucherParams {
  products?: Array<IProductsProps>
  paymentMethod: PaymentMethodCode
  voucherCode: string
  idempotentKey: string
  checkoutSource: string
  bookingId?: string | string[]
  onSuccess?: () => void
}

export const useCheckVoucher = ({
  products,
  idempotentKey,
  paymentMethod,
  voucherCode,
  checkoutSource,
  bookingId,
  onSuccess = () => {},
}: ICheckVoucherParams) => {
  const enableNativePayment = useFeatureFlags('native-payment')
  const { setPreCheckoutWithVoucher, setErrorPayment } =
    usePaymentStore((state) => ({
      setPreCheckoutWithVoucher: state.setPreCheckoutWithVoucher,
      setErrorPayment: state.setErrorPayment,
    }))
  const {
    data: checkVoucherData,
    isFetching: isCheckVoucherFetching,
    isError: isErrorCheckVoucher,
    error: errorCheckVoucher,
    refetch: fetchCheckVoucher,
    isFetched: isFetchedCheckVoucher,
  } = useQuery<IPreCheckoutData, AxiosError>({
    queryKey: ['check-voucher', voucherCode],
    queryFn: async () => {
      try {
        let response: IPreCheckoutData
        if (!isEmpty(bookingId)) {
          if (enableNativePayment) {
            response = await eventVoucherCheckV2({
              book_session_id: String(bookingId),
              payment_method: paymentMethod,
              idempotentKey: idempotentKey,
              voucher_code: voucherCode.toUpperCase(),
            })
          } else {
            response = await eventVoucherCheck({
              book_session_id: String(bookingId),
              payment_method: paymentMethod,
              idempotentKey: idempotentKey,
              voucher_code: voucherCode.toUpperCase(),
            })
          }
        } else {
          response = await voucherCheck({
            products: products,
            voucher_code: voucherCode.toUpperCase(),
            payment_method: paymentMethod,
            idempotentKey: idempotentKey,
            checkout_source: checkoutSource,
          })
        }
        setPreCheckoutWithVoucher(response)
        onSuccess()
        return response
      } catch (error) {
        setErrorPayment(error)
        throw error
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false,
  })

  return {
    checkVoucherData,
    isCheckVoucherFetching,
    isErrorCheckVoucher,
    errorCheckVoucher,
    fetchCheckVoucher,
    isFetchedCheckVoucher,
  }
}
