import { Wrapper } from './styled'
import { useTheme } from '@mui/material/styles'
import { IProps } from './interface'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from 'components/commons/Box'
import RequestIdentifierLabel from 'components/commons/RequestIdentifierLabel'

function Attention(props: IProps & { requestId?: string }) {
  const theme = useTheme()
  return (
    <Wrapper
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={props.sx}
    >
      <Box sx={{ mb: '26px', textAlign: 'center' }}>
        {props.image && <Box sx={{ mb: '28px' }}>{props.image}</Box>}
        <Typography
          sx={{ mb: '8px' }}
          color={theme.palette.text.primary}
          fontSize="20px"
          fontWeight="700"
        >
          {props.title}
        </Typography>
        {props.subtitle !== '' && (
          <Box sx={props.sxSubtitle}>
            <Typography
              color={theme.palette.text.secondary}
              fontSize="14px"
              fontWeight="400"
            >
              {props.subtitle}
            </Typography>
          </Box>
        )}
        {props.requestId && (
          <RequestIdentifierLabel id={props.requestId} />
        )}
      </Box>
      {props.actions.length > 0 && (
        <Stack
          direction="row"
          alignItems="center"
          justifyItems="center"
          justifyContent="space-evenly"
          spacing={1}
          sx={{ width: '100%', textAlign: 'center' }}
        >
          {props.actions.map((action, index) => (
            <Box key={index} sx={{ width: '100%' }}>
              {action}
            </Box>
          ))}
        </Stack>
      )}
    </Wrapper>
  )
}

Attention.defaultProps = {
  image: null,
  subtitle: '',
  actions: [],
}

export default Attention
