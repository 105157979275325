import { MouseEventHandler, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

// @mui
import { useMediaQuery, useTheme } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

// components
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import {
  CoinInfoContainer,
  PromoDesc,
  StyledArrow,
  StyledArrowRightContainer,
  StyledCheck,
  StyledCoin,
  StyledContainerVoucher,
  StyledDesc,
  StyledIconButton,
  StyledTags,
} from 'components/domains/Wallet/DirectPaymentDrawerContent/styled'
import LoadingButton from 'components/commons/LoadingButton'

// types
import { IPreCheckoutData } from 'types/payment'
import { IPartnerConfig } from 'types/partner'

// helpers
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import { isGuestMode } from 'helpers/cookie'

interface IPaymentActionsContainerProps {
  showPaymentActions?: boolean
  handleClickVoucherContainer: MouseEventHandler<HTMLDivElement>
  isAppliedVoucher: boolean
  isDisableVoucherContainer: boolean
  paymentConfirmationLabel: string
  handleClearVoucher: MouseEventHandler<HTMLButtonElement>
  disableVoucherReason: string
  isLoading: boolean
  onClickPaymentButton: MouseEventHandler<HTMLButtonElement>
  paymentButtonLabel: string
  preCheckoutData: IPreCheckoutData
  selectedPaymentMethodIndex: number
  isCoinMethod: boolean
  buttonSubmitPaymentId?: string
  isPageView?: boolean
  isDisablePaymentAction?: boolean
  visibility?: IPartnerConfig
}

const PaymentActionsContainer = ({
  showPaymentActions = true,
  handleClickVoucherContainer,
  isAppliedVoucher,
  isDisableVoucherContainer,
  paymentConfirmationLabel,
  handleClearVoucher,
  disableVoucherReason,
  isLoading,
  onClickPaymentButton,
  paymentButtonLabel,
  preCheckoutData,
  selectedPaymentMethodIndex,
  isCoinMethod,
  buttonSubmitPaymentId = 'b-payment-pay-checkout',
  isPageView = true,
  isDisablePaymentAction,
  visibility,
}: IPaymentActionsContainerProps) => {
  const theme = useTheme()
  const isDesktopResolution = useMediaQuery(
    theme.breakpoints.up('sm'),
  )
  const { t } = useTranslation()

  const isCoinPaymentDetail = Boolean(preCheckoutData?.coins?.length)

  const renderTotalPrice = () => {
    if (isCoinPaymentDetail)
      return NumberBaseFormatter(
        preCheckoutData?.payment_method?.[selectedPaymentMethodIndex]
          .total,
      )

    if (preCheckoutData?.payment_method)
      return NumberBaseFormatter(
        preCheckoutData?.payment_method?.[selectedPaymentMethodIndex]
          ?.price?.total_price,
      )

    return NumberBaseFormatter(preCheckoutData?.payment_info?.total)
  }

  const voucherPrice = useMemo(() => {
    if (preCheckoutData?.payment_method?.length)
      return {
        totalPriceBeforeDiscount:
          preCheckoutData?.payment_method?.[
            selectedPaymentMethodIndex
          ]?.price?.total_price_before_discount || 0,
        totalPrice:
          preCheckoutData?.payment_method?.[
            selectedPaymentMethodIndex
          ]?.price?.total_price || 0,
      }
    return {
      totalPriceBeforeDiscount:
        preCheckoutData?.payment_info?.total_price_before_discount ||
        0,
      totalPrice: preCheckoutData?.payment_info?.total || 0,
    }
  }, [preCheckoutData])

  const TotalPaymentSection = () => {
    return (
      <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
        {!isCoinMethod ? (
          <>
            <Typography
              variant="normalBold"
              id="c-payment-total-price-value"
            >
              Rp{NumberBaseFormatter(voucherPrice.totalPrice)}
            </Typography>
            {isAppliedVoucher && (
              <Typography
                variant="smallRegular"
                sx={{
                  textDecoration: 'line-through',
                  color: theme.palette.text.secondary,
                }}
              >
                Rp{' '}
                {NumberBaseFormatter(
                  voucherPrice.totalPriceBeforeDiscount,
                )}
              </Typography>
            )}
          </>
        ) : (
          <>
            <Typography
              variant="normalBold"
              id="c-payment-total-price-value"
            >
              Rp
              {renderTotalPrice()}
            </Typography>
            {!isCoinPaymentDetail && (
              <Typography
                variant="normalBold"
                display={'flex'}
                alignItems={'center'}
              >
                (
                <StyledCoin />
                {NumberBaseFormatter(
                  preCheckoutData?.payment_method?.[
                    selectedPaymentMethodIndex
                  ].coin.total_price,
                )}
                )
              </Typography>
            )}
          </>
        )}
      </Stack>
    )
  }

  return (
    <Stack
      spacing={2}
      sx={{
        position: isPageView ? 'fixed' : 'inherit',
        background: isPageView
          ? theme.palette.background.primary
          : 'transparent',
        width: '100%',
        left: 0,
        bottom: 0,
        zIndex: 10,
        padding: isPageView ? '16px' : 0,
        boxShadow: isPageView
          ? `0px 0px 24px 0px ${theme.palette.border.subtitle}`
          : 'none',
        [theme.breakpoints.up('md')]: {
          position: 'inherit',
          background: 'transparent',
          boxShadow: 'none',
          padding: 0,
        },
      }}
    >
      {showPaymentActions &&
        !isCoinPaymentDetail &&
        !isGuestMode() && (
          <>
            <StyledContainerVoucher
              sx={{ margin: 0 }}
              onClick={handleClickVoucherContainer}
              id="i-payment-promobox"
              data-testid="i-payment-promobox"
              border={
                isAppliedVoucher ? String(isAppliedVoucher) : ''
              }
              disabled={
                isDisablePaymentAction ||
                isDisableVoucherContainer ||
                isAppliedVoucher ||
                isLoading
              }
              isError={isDisableVoucherContainer}
            >
              <StyledDesc
                sx={{
                  [theme.breakpoints.up('sm')]: {
                    background: isDisableVoucherContainer
                      ? 'transparent'
                      : 'white',
                  },
                }}
              >
                {isDisableVoucherContainer ? (
                  ''
                ) : isAppliedVoucher ? (
                  <StyledCheck />
                ) : (
                  <StyledTags />
                )}
                <Typography
                  sx={{
                    color: isLoading
                      ? theme.palette.text.tertiery
                      : theme.palette.text.primary,
                  }}
                >
                  {paymentConfirmationLabel}
                </Typography>
              </StyledDesc>
              {isAppliedVoucher ? (
                <StyledIconButton
                  disabled={isLoading}
                  onMouseDown={(event) => {
                    event.preventDefault()
                  }}
                  onClick={(event) => {
                    handleClearVoucher(event)
                    event.stopPropagation()
                  }}
                >
                  <HighlightOffIcon />
                </StyledIconButton>
              ) : (
                <StyledArrowRightContainer>
                  <StyledArrow
                    fontSize="small"
                    disabled={isDisableVoucherContainer || isLoading}
                  />
                </StyledArrowRightContainer>
              )}
            </StyledContainerVoucher>
            {isDisableVoucherContainer && (
              <CoinInfoContainer>
                <PromoDesc
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ color: theme.palette.error.main }}
                  spacing={1}
                >
                  {disableVoucherReason}
                </PromoDesc>
              </CoinInfoContainer>
            )}
          </>
        )}

      <Stack
        direction={
          isDesktopResolution || !isPageView ? 'column' : 'row'
        }
        justifyContent={
          (visibility?.useThemePartner || !isDesktopResolution) &&
          'space-between'
        }
        alignItems={
          (visibility?.useThemePartner || !isDesktopResolution) &&
          'center'
        }
      >
        {!isDesktopResolution &&
          (isPageView || visibility?.useThemePartner) && (
            <Stack>
              <Typography variant="normalRegular">
                {t('paymentConfirmationBottomSheetTotal')}
              </Typography>
              <TotalPaymentSection />
            </Stack>
          )}
        {showPaymentActions && (
          <LoadingButton
            id={buttonSubmitPaymentId}
            sx={
              visibility?.useThemePartner || !isDesktopResolution
                ? {
                    height: '44px',
                  }
                : { borderRadius: '8px', width: '100%' }
            }
            disabled={isLoading || isDisablePaymentAction}
            onClick={onClickPaymentButton}
            loading={isLoading}
          >
            {paymentButtonLabel}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  )
}

export default PaymentActionsContainer
