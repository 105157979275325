import { ThemeKeys } from 'constants/common'
import { StateCreator } from 'zustand'
import ICustomTheme from './interface'

export const createCustomThemeStore: StateCreator<ICustomTheme> = (
  set,
) => ({
  partner: ThemeKeys.DEFAULT,
  setCustomTheme: (newPartner) => set({ partner: newPartner }),
  resetCustomTheme: () => set({ partner: ThemeKeys.DEFAULT }),
})
