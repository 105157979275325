import * as Sentry from '@sentry/nextjs'
import { severityLevelFromString } from '@sentry/utils'
import sentryConfig from 'configs/sentry'
import { getCookie } from 'helpers/cookie'
import { getErrorMessage } from 'helpers/error'
import { generateShortUuid } from 'helpers/generate/uuid'
import isEmpty from 'helpers/is-empty'
const sendExceptionToSentry = (error: Error) => {
  Sentry.captureException(error)
}

function initLogger(): void {
  if (sentryConfig.enabled === 'true') {
    const userId = getCookie('user_id')
    const username = getCookie('username')
    const email = getCookie('email')
    if (!isEmpty(userId)) {
      setLogUser(userId, username, email)
    }
  }
}

/**
 * @param {Error} error
 * @return {Void}
 */
function sendError(error: Error): void {
  if (sentryConfig.enabled === 'true') {
    sendExceptionToSentry(error)
  }
}

function setLogUser(
  userId: string,
  userName?: string,
  email?: string,
): void {
  if (sentryConfig.enabled === 'true') {
    Sentry.setUser({ id: userId, username: userName, email: email })
  }
}

function resetLogUser(): void {
  if (sentryConfig.enabled === 'true') {
    Sentry.configureScope((scope) => scope.setUser(null))
  }
}

function sendLog(
  message: string,
  level: Sentry.SeverityLevel = severityLevelFromString('info'),
): void {
  let msg = message || {}
  let errorMessage = JSON.parse(JSON.stringify(msg))
  if (typeof message !== 'string')
    errorMessage = getErrorMessage(message)
  if (sentryConfig.enabled === 'true') {
    addBreadcrumb('log error', errorMessage, 'info', {
      error: message,
    })
    setTag('raw_error', message)
    Sentry.captureMessage(errorMessage, level)
  }
}

function setTag(tagName: string, value?: string): void {
  if (sentryConfig.enabled === 'true') {
    Sentry.setTag(tagName, value)
  }
}

function addBreadcrumb(
  category: string,
  message: string,
  level: Sentry.SeverityLevel,
  data?: {
    [key: string]: any
  },
): void {
  if (sentryConfig.enabled === 'true') {
    Sentry.addBreadcrumb({
      category,
      message,
      level,
      data,
    })
  }
}

function addIncrementMetric(event, tags) {
  if (sentryConfig.enabled === 'true') {
    Sentry.metrics.increment(event, 1, {
      tags,
    })
  }
}

function getRequestID(): string {
  const currentTransaction = Sentry.getCurrentHub()
    ?.getScope()
    ?.getTransaction()
  const baseTraceId = currentTransaction?.traceId || ''
  const shortUuid = generateShortUuid(8)
  return baseTraceId ? `${baseTraceId}-${shortUuid}` : shortUuid
}

export {
  initLogger,
  sendError,
  setLogUser,
  resetLogUser,
  sendLog,
  setTag,
  addBreadcrumb,
  addIncrementMetric,
  getRequestID,
}
