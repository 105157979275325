import mixpanel from 'mixpanel-browser'
import mixPanelConfig from 'configs/mixpanel'
import openpanelConfig from 'configs/openpanel'
import stateMapping from 'constants/state-mapping'
import isEmpty from 'helpers/is-empty'
import get from 'lodash/get'
import { getCookie } from 'helpers/cookie'
import { getQueryParam } from 'helpers/url'
import { ISessionDetailData } from 'stores/domains/Session/SessionDetail/interface'
import {
  externalMetaPixelWhitelist,
  openPanelWhitelist,
  mixPanelWhitelist,
} from 'constants/analytics/whitelist'
import { OpenPanel } from '@openpanel/web'
import {
  setLocalStorage,
  getLocalStorage,
  destroyLocalStorage,
} from 'helpers/local-storage'
import { setSessionStorage } from 'helpers/session-storage'
import { trackExternalPixelEvent } from 'helpers/facebook-pixel'

export const op = new OpenPanel({
  clientId: openpanelConfig.key,
  trackScreenViews: true,
  trackOutgoingLinks: true,
  trackAttributes: true,
} as any)

const OPENPANEL_STORAGE_KEY = 'openpanel'

function initAnalytics(): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true'
  ) {
    mixpanel.init(mixPanelConfig.token, {
      ignore_dnt: true,
      debug: mixPanelConfig.debug === 'true',
      persistence: 'localStorage',
      loaded: function (mixpanel) {
        const deviceId = mixpanel?.get_property('$device_id')
        setSessionStorage('fp', deviceId)
        // do identify for logged in user
        // const userId = getCookie('user_id')
        // if (!isEmpty(userId)) {
        //   mixpanel.identify(userId)
        // }
        campaignParams()
      },
    })
  }

  if (
    openpanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    openpanelConfig.key
  ) {
    // Register user ID as profile ID on OpenPanel
    const userId = getCookie('user_id')
    const email = getLocalStorage('email')
    const uname = getCookie('username')
    const profileTag = getLocalStorage('profileTag')
    op.identify({
      profileId: userId,
      alias: uname,
      email: email,
      firstName: uname,
      properties: {
        user_id: userId,
        birth_date: profileTag?.birth_date,
        creator_class: profileTag?.creator_class,
        gender: profileTag?.gender,
        username: uname,
        email: email,
      },
    } as any)

    setDefaultProperties({
      'User ID': userId,
    })
  }
}

function trackEvent(eventName: string, eventProperties?: any): void {
  if (externalMetaPixelWhitelist.includes(eventName)) {
    if (
      eventProperties?.event_id ===
      process.env
        .NEXT_PUBLIC_EXTERNAL_FACEBOOK_PIXEL_WHITELIST_PRODUCT_ID
    ) {
      trackExternalPixelEvent(eventName, eventProperties)
    }
  }
  //whitelisted events
  if (mixPanelWhitelist.includes(eventName)) {
    if (
      mixPanelConfig.enabled === 'true' &&
      process.env.NEXT_IS_SERVER !== 'true' &&
      mixpanel.config.token
    ) {
      mixpanel.track(eventName, eventProperties, {
        send_immediately: true,
      })
    }
  }
  if (openPanelWhitelist.includes(eventName)) {
    if (
      openpanelConfig.enabled === 'true' &&
      process.env.NEXT_IS_SERVER !== 'true' &&
      openpanelConfig.key
    ) {
      // get global properties from local storage
      const currentGlobalProps = getLocalStorage(
        OPENPANEL_STORAGE_KEY,
      )
      op.track(eventName, {
        ...currentGlobalProps,
        ...eventProperties,
      })
    }
  }
}

function setDefaultProperties(properties?: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.register(properties)
  }

  if (
    openpanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    openpanelConfig.key
  ) {
    const currentGlobalProps = getLocalStorage(OPENPANEL_STORAGE_KEY)
    setLocalStorage(OPENPANEL_STORAGE_KEY, {
      ...currentGlobalProps,
      ...properties,
    })

    op.setGlobalProperties(properties as any)
  }
}

function setUserRegister(
  userId: string,
  properties?: Object | any,
): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.alias(userId, mixpanel.get_distinct_id())
    // mixpanel.identify(userId)
    mixpanel.people.set(properties)
  }

  if (
    openpanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    openpanelConfig.key
  ) {
    const userId = getCookie('user_id')
    const email = getLocalStorage('email')
    const uname = getCookie('username')
    const profileTag = getLocalStorage('profileTag')
    op.identify({
      'User ID': userId,
      username: properties?.$name || '',
      profileId: userId,
      alias: uname,
      email: email,
      firstName: uname,
      properties: {
        user_id: userId,
        birth_date: profileTag?.birth_date,
        creator_class: profileTag?.creator_class,
        gender: profileTag?.gender,
        username: uname,
        email: email,
      },
    } as any)
  }
}

function setUserLogin(
  _userId: string,
  properties?: Object | any,
): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    // mixpanel.identify(userId)
    mixpanel.people.set(properties)
  }

  if (
    openpanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    openpanelConfig.key
  ) {
    const userId = getCookie('user_id')
    const email = getLocalStorage('email')
    const uname = getCookie('username')
    const profileTag = getLocalStorage('profileTag')
    op.identify({
      'User ID': userId,
      username: properties?.$name || '',
      profileId: userId,
      alias: uname,
      email: email,
      firstName: uname,
      properties: {
        user_id: userId,
        birth_date: profileTag?.birth_date,
        creator_class: profileTag?.creator_class,
        gender: profileTag?.gender,
        username: uname,
        email: email,
      },
    } as any)
  }
}

function resetUser(): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.reset()
    op.clear()
  }

  if (
    openpanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    openpanelConfig.key
  ) {
    op.clear()
    destroyLocalStorage(OPENPANEL_STORAGE_KEY)
  }
}

function setPeopleProperties(properties: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.people.set(properties)
  }
}

function setOncePeopleProperties(properties: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.people.set_once(properties)
  }
}

function appendListToPeopleProperties(list: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.people.append(list)
  }
}

function unionListToPeopleProperties(list: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.people.union(list)
  }
}

function removeListFromPeopleProperties(list: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.people.remove(list)
  }
}

function incrementPeopleProperties(properties: Object): void {
  if (
    mixPanelConfig.enabled === 'true' &&
    process.env.NEXT_IS_SERVER !== 'true' &&
    mixpanel.config.token
  ) {
    mixpanel.people.increment(properties)
  }
}

function getStateFromSource(source: string): string {
  const index = stateMapping.findIndex(
    (state) => state?.source === source,
  )
  return isEmpty(stateMapping[index]?.state)
    ? ''
    : stateMapping[index].state
}

function campaignParams() {
  var campaign_keywords =
      'utm_source utm_medium utm_campaign utm_content utm_term'.split(
        ' ',
      ),
    keyword = '',
    params = {},
    first_params = {}
  var index
  for (index = 0; index < campaign_keywords.length; ++index) {
    keyword = getQueryParam(document.URL, campaign_keywords[index])
    if (keyword.length) {
      params[campaign_keywords[index] + ' [last touch]'] = keyword
    }
  }
  for (index = 0; index < campaign_keywords.length; ++index) {
    keyword = getQueryParam(document.URL, campaign_keywords[index])
    if (keyword.length) {
      first_params[campaign_keywords[index] + ' [first touch]'] =
        keyword
    }
  }
  mixpanel.people.set(params)
  mixpanel.people.set_once(first_params)
  mixpanel.register(params)
}

function trackMixPanelSession(
  eventName: string,
  sessionDetail: ISessionDetailData,
  additionalProperties = {},
) {
  trackEvent(eventName, {
    ...additionalProperties,
    session_status: get(
      sessionDetail,
      'sessionData.session_status',
      '',
    ).toLowerCase(),
    session_type: get(sessionDetail, 'sessionData.session_type'),
    session_title: get(sessionDetail, 'sessionData.title'),
    session_price_per_participant: get(
      sessionDetail,
      'sessionData.price',
      0,
    ),
    max_num_participant: get(sessionDetail, 'sessionData.slots', 0),
    session_description: get(
      sessionDetail,
      'sessionData.description',
    ),
    estimate_session_start_datetime: get(
      sessionDetail,
      'sessionData.start_time',
    ),
    estimate_session_duration:
      get(sessionDetail, 'sessionData.duration', 0) * 60, // seconds
    session_photo_image_path: get(sessionDetail, 'sessionData.cover'),
    creator_id: get(
      sessionDetail,
      'sessionData.creator.creator_id',
      '',
    ),
    creator_name: get(sessionDetail, 'sessionData.creator.name', ''),
    creator_user_id: get(
      sessionDetail,
      'sessionData.creator.creator_user_id',
      '',
    ),
    creator_category: get(
      sessionDetail,
      'sessionData.creator.category.name',
      '',
    ),
    creator_category_id: get(
      sessionDetail,
      'sessionData.creator.category.id',
      '',
    ),
  })
}

export {
  initAnalytics,
  trackEvent,
  setDefaultProperties,
  setUserRegister,
  setUserLogin,
  resetUser,
  setPeopleProperties,
  setOncePeopleProperties,
  appendListToPeopleProperties,
  unionListToPeopleProperties,
  removeListFromPeopleProperties,
  incrementPeopleProperties,
  getStateFromSource,
  trackMixPanelSession,
}
