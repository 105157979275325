import {
  IconJcb,
  IconMasterCard,
  IconVisa,
} from 'containers/domains/Payment/CardForm/styled'
import { AvailableCardBrandEnum } from 'types/payment'
interface IPaymentCardIconRenderProps {
  cardBrand: string
}
const PaymentCardIcon = ({
  cardBrand,
}: IPaymentCardIconRenderProps) => {
  switch (cardBrand) {
    case AvailableCardBrandEnum.JCB:
      return <IconJcb />
    case AvailableCardBrandEnum.VISA:
      return <IconVisa />
    case AvailableCardBrandEnum.MASTERCARD:
      return <IconMasterCard />
    default:
      return <></>
  }
}

export default PaymentCardIcon
