import { Tabs, useTheme } from '@mui/material'
import Box from 'components/commons/Box'
import { useMemo, useState } from 'react'
import { IPaymentInstructionList } from 'types/payment'
import {
  StyledLink,
  StyledSteps,
  StyledSubSteps,
  StyledTab,
} from '../../styled'
import { urlOrVADigitNumberRegex } from 'constants/regex'
import { validateURL } from 'helpers/url/convert-url'
import ClickableTextWithTooltip from 'components/commons/ClickableTextWithTooltip'
import { copyToClipboard } from 'helpers/clipboard'
import htmlParse from 'html-react-parser'

const convertUrlsToLinks = (
  text: string,
  callback: Function,
  toolTip: boolean,
  toolTipCloser?: Function,
) => {
  const VA_LENGTH = 20
  // Convert URL into clickable links
  return text
    .split(urlOrVADigitNumberRegex)
    .map((splittedText, index) =>
      // link will be store on index 2 after split
      // apart from index number 2, it is plain text
      index % 2 === 0 ? (
        htmlParse(splittedText)
      ) : splittedText.length === VA_LENGTH ? ( // Check if the length matches the VA length
        <ClickableTextWithTooltip
          callback={callback}
          isOpenTooltip={toolTip}
          text={splittedText}
          key={index}
          toolTipCloser={toolTipCloser}
        />
      ) : (
        <StyledLink
          href={`${validateURL(splittedText)}`}
          target={`_blank`}
          rel="noreferrer"
        >
          {splittedText}
        </StyledLink>
      ),
    )
}

const PaymentInstructionTab = ({
  paymentInstructionList,
  isPageView = false,
}: {
  paymentInstructionList: IPaymentInstructionList
  isPageView?: boolean
}) => {
  const theme = useTheme()
  const [currentActiveTab, setCurrentActiveTab] = useState(
    paymentInstructionList?.instructions?.[0]?.tab_name,
  )
  const [open, setOpen] = useState(false)

  const selectedInstruction = useMemo(() => {
    const selectedInstruction =
      paymentInstructionList?.instructions?.find(
        (item) => item.tab_name === currentActiveTab,
      )
    return selectedInstruction?.points || selectedInstruction?.step
  }, [currentActiveTab, paymentInstructionList])

  return (
    <>
      <Box>
        <Tabs
          variant="fullWidth"
          value={currentActiveTab}
          onChange={(_event, val) => {
            setCurrentActiveTab(val)
          }}
          sx={{
            borderBottom: `1px solid ${theme.palette.background.tertiery}`,
          }}
        >
          {paymentInstructionList?.instructions?.map(
            (action, index) => {
              return (
                <StyledTab
                  key={index}
                  label={action?.tab_name}
                  value={action?.tab_name}
                />
              )
            },
          )}
        </Tabs>
      </Box>
      <Box
        sx={{
          marginBottom: '16px',
          marginTop: '16px',
          color: theme.palette.text.primary,
          ...(!isPageView && {
            overflowY: 'scroll',
            height: '200px',
          }),
        }}
        data-testid={`c-payment-instruction-${currentActiveTab}`}
      >
        {typeof selectedInstruction === 'string' ? (
          <>
            {convertUrlsToLinks(
              selectedInstruction,
              () =>
                copyToClipboard(
                  paymentInstructionList?.va ||
                    paymentInstructionList?.va_detail?.va,
                  () => setOpen(true),
                ),
              open,
              () => {
                setOpen(false)
              },
            )}
          </>
        ) : (
          selectedInstruction?.map((titleStep, index) => {
            return (
              <div key={index}>
                <StyledSteps>{titleStep.title}</StyledSteps>
                <ol>
                  {titleStep?.steps?.map((step, index) => {
                    return (
                      <StyledSubSteps
                        data-testid={`c-payment-step-${index}`}
                        key={index}
                      >
                        {convertUrlsToLinks(
                          step,
                          () =>
                            copyToClipboard(
                              paymentInstructionList?.va,
                              () => setOpen(true),
                            ),
                          open,
                          () => {
                            setOpen(false)
                          },
                        )}
                      </StyledSubSteps>
                    )
                  })}
                </ol>
              </div>
            )
          })
        )}
      </Box>
    </>
  )
}

export default PaymentInstructionTab
