import { useEffect, useState } from 'react'
import { Skeleton } from '@mui/material'
import htmlParse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

import trackEvent from 'trackers'
import { IVoucherCard } from 'types/campaign'
import { PaymentMethodCode } from 'types/payment'
import NumberBaseFormatter from 'helpers/formats/number-base-format'

import {
  CoinInfoContainer,
  ErrorDesc,
  ErrorLabel,
  MainTypography,
  StyledDivider,
  StyledErrorMessage,
  SubTypography,
} from '../VoucherInbox/styled'
import VoucherCard from 'components/domains/Payment/VoucherCard'
import Box from 'components/commons/Box'
import StateImage from 'components/commons/StateImage'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import { IPaymentCheckoutBody } from 'services/payment'
import {
  useCheckVoucher,
  useVoucherInbox,
} from 'hooks/domains/Payment/useVoucher'
import VoucherInboxInput from './VoucherInboxInput'
import isEmpty from 'helpers/is-empty'
import InstallmentCancellationModal from 'containers/domains/Payment/InstallmentCancellationModal'
import { usePaymentStore } from 'stores/domains/Payment'

function VoucherSkeleton() {
  return (
    <Box>
      <Skeleton
        variant="rectangular"
        height={211}
        width={'100%'}
        style={{
          marginTop: 16,
          background: 'lightgrey',
          borderRadius: '8px',
        }}
      />
    </Box>
  )
}

export interface IVoucherInboxModalProps {
  handleClearVoucher: Function
  voucherTracker: any
  isOpenVoucherInboxModal?: boolean
  onClose: Function
  selectedProducts: IPaymentCheckoutBody
  selectedPaymentMethod: PaymentMethodCode
  renderWithModalWrapper?: boolean
  bookingId?: string | string[]
  selectedInstallmentData?: {
    installment_amount: number
    count: number
  }
  onCancelInstallment?: Function
}

export default function VoucherInboxModal({
  handleClearVoucher,
  voucherTracker,
  isOpenVoucherInboxModal,
  onClose,
  selectedProducts,
  selectedPaymentMethod,
  renderWithModalWrapper = true,
  bookingId = '',
  selectedInstallmentData,
  onCancelInstallment,
}: IVoucherInboxModalProps) {
  const { t } = useTranslation()
  const [selectCheckedVoucher, setSelectCheckedVoucher] = useState('')
  const [selectedVoucherDetail, setSelectedVoucherDetail] =
    useState<IVoucherCard>(null)
  const [
    alertInstallmentCancellation,
    setAlertInstallmentCancellation,
  ] = useState(false)

  const handleCloseModalDrawer = () => {
    if (selectedVoucherDetail) setSelectedVoucherDetail(null)
    onClose()
  }

  const { preCheckoutWithVoucher, resetErrorPayment } =
    usePaymentStore((state) => ({
      preCheckoutWithVoucher: state.preCheckoutWithVoucher,
      resetErrorPayment: state.resetErrorPayment,
    }))

  const { isVoucherInboxFetching, voucherInboxData } =
    useVoucherInbox({
      checkout_source: selectedProducts.checkout_source,
      idempotentKey: selectedProducts.idempotentKey,
      payment_method: selectedPaymentMethod,
      products: selectedProducts.products,
    })

  const {
    isCheckVoucherFetching,
    errorCheckVoucher,
    isErrorCheckVoucher,
    fetchCheckVoucher,
  } = useCheckVoucher({
    products: selectedProducts.products,
    checkoutSource: selectedProducts.checkout_source,
    idempotentKey: selectedProducts.idempotentKey,
    paymentMethod: selectedPaymentMethod as PaymentMethodCode,
    voucherCode: selectCheckedVoucher,
    bookingId,
    onSuccess: handleCloseModalDrawer,
  })

  const handleSelectVoucher = (voucherCode) => {
    if (selectedInstallmentData?.count > 1)
      setAlertInstallmentCancellation(true)
    setSelectCheckedVoucher(voucherCode)
  }

  useEffect(() => {
    resetErrorPayment()
    if (!isEmpty(selectCheckedVoucher)) {
      if (!isEmpty(bookingId) && selectedInstallmentData?.count > 1)
        setAlertInstallmentCancellation(true)
      else fetchCheckVoucher()
    }
  }, [selectCheckedVoucher])

  const RenderVoucherStatus = () => {
    if (errorCheckVoucher?.response?.data?.code) {
      return (
        <StyledErrorMessage>
          {errorCheckVoucher?.response?.data?.message}
        </StyledErrorMessage>
      )
    }
  }

  const renderVoucherSection = () => {
    return (
      <>
        {selectedVoucherDetail === null ? (
          <Box>
            <MainTypography>
              {t('voucherInboxScreenSearchBarTitle')}
            </MainTypography>
            <SubTypography>
              {t('voucherInboxScreenSearchBarDesc')}
            </SubTypography>
            <VoucherInboxInput
              onBlur={(inputVoucherCode) => {
                trackEvent.paymentCart(
                  `click_search_box`,
                  voucherTracker.trackerData,
                  {
                    keyword_search: inputVoucherCode,
                  },
                )
              }}
              onClearInput={handleClearVoucher}
              checkVoucherData={preCheckoutWithVoucher}
              isErrorCheckVoucher={isErrorCheckVoucher}
              isLoading={
                isVoucherInboxFetching || isCheckVoucherFetching
              }
              onSubmitButtonInput={handleSelectVoucher}
            />
            <CoinInfoContainer>
              {RenderVoucherStatus()}
            </CoinInfoContainer>
            {!isVoucherInboxFetching &&
              voucherInboxData?.length > 0 && (
                <MainTypography>
                  <StyledDivider />
                  {t('key_or_user_voucher')}
                </MainTypography>
              )}
            {isVoucherInboxFetching ? (
              <VoucherSkeleton />
            ) : voucherInboxData?.length ? (
              voucherInboxData.map((item, itemIndex) => {
                return (
                  <VoucherCard
                    key={itemIndex}
                    voucherItem={item}
                    voucherChecker={handleSelectVoucher}
                    voucherDetailsHandler={setSelectedVoucherDetail}
                    isLoading={
                      isVoucherInboxFetching || isCheckVoucherFetching
                    }
                    trackerData={voucherTracker.trackerData}
                  />
                )
              })
            ) : (
              <Box>
                <StateImage
                  type="error"
                  sx={{
                    marginBottom: '16px',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
                <ErrorLabel>
                  {t('voucherInboxScreenErrorFetchTitle')}
                </ErrorLabel>
                <ErrorDesc>
                  {t('voucherInboxScreenErrorFetchDesc')}
                </ErrorDesc>
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            <VoucherCard
              voucherItem={selectedVoucherDetail}
              voucherChecker={handleSelectVoucher}
              isLoading={isVoucherInboxFetching}
            />
            <MainTypography>
              {selectedVoucherDetail?.name}
            </MainTypography>
            <SubTypography>
              <li>
                Potongan sampai dengan{' '}
                {NumberBaseFormatter(
                  selectedVoucherDetail?.max_discount_amount,
                )}
              </li>
            </SubTypography>
            <StyledDivider />
            <MainTypography>
              {htmlParse(selectedVoucherDetail?.description)}
            </MainTypography>
          </Box>
        )}

        <InstallmentCancellationModal
          isOpen={alertInstallmentCancellation}
          onClose={() => setAlertInstallmentCancellation(false)}
          onClickCancel={() => setAlertInstallmentCancellation(false)}
          onClickContinue={() => {
            fetchCheckVoucher()
            setAlertInstallmentCancellation(false)
            onCancelInstallment()
          }}
        />
      </>
    )
  }

  if (renderWithModalWrapper) {
    return (
      <ModalDrawer
        title="Voucher Inbox"
        isOpen={isOpenVoucherInboxModal}
        onClose={handleCloseModalDrawer}
      >
        {renderVoucherSection()}
      </ModalDrawer>
    )
  }

  return renderVoucherSection()
}
