import { isAndroid } from 'helpers/user-agent'
import { useTheme } from '@mui/material/styles'
import { useQRCode } from 'next-qrcode'

export default function Barcode({ code = '' }) {
  const { Canvas, SVG } = useQRCode()
  const theme = useTheme()
  const QR_WIDTH = 240
  // Android Browsers modify css to follow devices theme causing barcodes to be unscannable
  // Thread: https://tiptipworkspace.slack.com/archives/C03DHUDPHSQ/p1717388406906199
  if (isAndroid()) {
    return (
      <div data-testid="barcode-canvas">
        <Canvas
          data-testid="barcode"
          text={code}
          options={{
            errorCorrectionLevel: 'L',
            margin: 0,
            scale: 1,
            width: QR_WIDTH,
            color: {
              dark: theme.palette.text.primary,
              light: theme.palette.background.primary,
            },
          }}
        />
      </div>
    )
  } else {
    return (
      <div data-testid="barcode-svg">
        <SVG
          data-testid="barcode"
          text={code}
          options={{
            margin: 0,
            width: QR_WIDTH,
            color: {
              dark: theme.palette.text.primary,
              light: theme.palette.background.primary,
            },
          }}
        />
      </div>
    )
  }
}
