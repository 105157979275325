import { getCookie } from 'helpers/cookie'
import { sendLog } from 'helpers/log'

export function getPartnerInfo(): {
  token: string | null
  partner: any
} | null {
  const partnerInfoString = getCookie('partnerInfo')
  if (!partnerInfoString) return null

  try {
    return JSON.parse(partnerInfoString)
  } catch (error) {
    sendLog(`Error parsing partnerInfo: ${error}`)
    return null
  }
}

export function getPartnerToken(): string | null {
  return getPartnerInfo()?.token || null
}

export function getPartner(): string | null {
  return getPartnerInfo()?.partner || null
}
