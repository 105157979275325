import { ElementType, ReactNode, useMemo } from 'react'
import { useRouter } from 'next/router'
import {
  styled,
  TypographyProps,
  Link,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import Box from '../Box'
import Stack from '../Stack'
import Typography from '../Typography'

import { IS_CREATOR_VERIFICATION } from 'constants/index'
import VerifiedIcon from './VerifiedIcon'
import { Circle } from './Circle'

import DiamondRoundedIcon from '@mui/icons-material/DiamondRounded'
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded'
import theme from 'theme'

type CreatorSizes = 'xs' | 'sm' | 'md' | 'lg'

export interface ICreatorCaption extends TypographyProps {
  isVerified?: boolean
  title?: string
  size?: CreatorSizes
  isRegular?: boolean
  isTruncate?: boolean
  isCreatorCard?: boolean
  onClickCreatorName?: Function
  username?: string
  children?: ReactNode
  isSubscriber?: boolean
  isSubscribing?: boolean
  isSupporter?: boolean
  maxLength?: number
  textColor?: string
  minWidthChildren?: string | number
  isEnabledClick?: boolean
}

export default function CreatorCaption({
  size = 'md',
  isVerified = false,
  isRegular = false,
  isTruncate = false,
  isCreatorCard = false,
  isSupporter = false,
  onClickCreatorName = () => {},
  title = '-',
  children,
  username = '',
  isSubscriber,
  isSubscribing,
  maxLength = 16,
  textColor = theme.palette.text.primary,
  minWidthChildren = '122px',
  isEnabledClick,
  ...rest
}: ICreatorCaption) {
  const isDesktopResolution = useMediaQuery(
    theme.breakpoints.up('sm'),
  )
  const { pathname } = useRouter()

  const { isDisplayBadge } = useMemo(
    () => ({
      isDisplayBadge: IS_CREATOR_VERIFICATION && isVerified,
    }),
    [IS_CREATOR_VERIFICATION, isVerified],
  )

  const heading = {
    lg: isRegular ? HeadingH2 : HeadingH2Bold,
    md: isRegular ? HeadingH3 : HeadingH3Bold,
    sm: isRegular ? HeadingH4 : HeadingH4Bold,
    xs: isRegular ? HeadingH5 : HeadingH5Bold,
  }

  const headingTypes: Record<CreatorSizes, ElementType> = {
    lg: 'h1',
    md: 'h1',
    sm: 'p',
    xs: 'span',
  }

  const style = heading[size]
  const Caption = style

  const substrTitle =
    (title.length > maxLength && isVerified) ||
    title.length > maxLength + 5
      ? `${title.substring(0, maxLength)}...`
      : title
  const formatTitle = children ? substrTitle : title

  const sourceAction = isSupporter
    ? 'click_supporter_profile'
    : 'click_creator_profile'

  return (
    <>
      <Caption
        as={headingTypes[size]}
        hasChild={!!children}
        isTruncate={isTruncate}
        {...rest}
      >
        <>
          {username && username.length > 0 ? (
            <Tooltip title="View User Profile" placement="top">
              <Link
                sx={{
                  textDecoration: 'none',
                  color: textColor,
                }}
                href={
                  isEnabledClick &&
                  `/p/${username}?source=${pathname}&source_action=${sourceAction}`
                }
                onClick={() => {
                  if (isEnabledClick) onClickCreatorName()
                }}
              >
                {formatTitle}
              </Link>
            </Tooltip>
          ) : (
            formatTitle
          )}
        </>
        {isDisplayBadge && !isCreatorCard && (
          <VerifiedIcon
            size={size === 'lg' && isDesktopResolution ? 'lg' : 'sm'}
          />
        )}
        {isSubscribing && (
          <Circle>
            <DiamondRoundedIcon fontSize="small" />
          </Circle>
        )}
        {isSubscriber && (
          <Circle marginLeft="1px">
            <HowToRegRoundedIcon fontSize="small" />
          </Circle>
        )}
        {children && (
          <Box minWidth={minWidthChildren}>{children}</Box>
        )}
      </Caption>
      {isDisplayBadge && isCreatorCard && (
        <BoxCreator>
          <VerifiedIcon
            size={size === 'lg' && isDesktopResolution ? 'lg' : 'sm'}
          />
          <TextBadge>Verified</TextBadge>
        </BoxCreator>
      )}
    </>
  )
}

const Heading = styled(Typography)<{
  hasChild?: boolean
  isTruncate?: boolean
}>(({ hasChild = false, isTruncate = false }) => {
  const truncateStyle = isTruncate && {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: hasChild ? 'calc(100% - 122px)' : '100%',
    WebkitLineClamp: 1,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
  }

  return {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    margin: 0,
    marginTop: '4px',
    ...truncateStyle,
  }
})

const HeadingH2 = styled(Heading)(({ theme }) => ({
  ...theme.typography.mediumRegular,
  color: theme.palette.text.primary,
  textAlign: 'center',
  justifyContent: 'center',
  textTransform: 'capitalize',
  letterSpacing: '0.01em',
  [theme.breakpoints.up('sm')]: {
    ...theme.typography.h3Desk,
    fontWeight: '400',
  },
}))

const HeadingH3 = styled(Heading)(({ theme }) => ({
  ...theme.typography.mediumRegular,
  color: theme.palette.text.primary,
  textTransform: 'capitalize',
  [theme.breakpoints.up('md')]: {
    ...theme.typography.h3,
    fontWeight: '400',
  },
}))

const HeadingH4 = styled(Heading)(({ theme }) => ({
  ...theme.typography.normalRegular,
  color: theme.palette.text.primary,
  textTransform: 'capitalize',
}))

const HeadingH5 = styled(Heading)(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.smallRegular,
}))

const HeadingH2Bold = styled(HeadingH2)(({ theme }) => ({
  fontWeight: 'bold',
  [theme.breakpoints.up('sm')]: {
    fontWeight: 'bold',
  },
}))

const HeadingH3Bold = styled(HeadingH3)(({ theme }) => ({
  fontWeight: 'bold',
  [theme.breakpoints.up('md')]: {
    fontWeight: 'bold',
  },
}))

const HeadingH4Bold = styled(HeadingH4)(({ theme }) => ({
  ...theme.typography.normalBold,
}))

const HeadingH5Bold = styled(HeadingH5)(({ theme }) => ({
  ...theme.typography.smallBold,
}))

const BoxCreator = styled(Stack)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  columnGap: '4px',
})

const TextBadge = styled(Typography)(({ theme }) => ({
  ...theme.typography.smallRegular,
  color: 'white',
  [theme.breakpoints.up('md')]: {
    ...theme.typography.mediumRegular,
  },
}))
