import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { eventPreCheckoutPayment } from 'services/payment'
import { usePaymentStore } from 'stores/domains/Payment'
import {
  IPreCheckoutResult,
  PaymentHandlerStrategy,
} from 'types/payment'

export const usePrecheckout = ({
  book_session_id,
  idempotentKey,
}) => {
  return useQuery({
    queryKey: [`preCheckout`],
    queryFn: async () => {
      const response = await eventPreCheckoutPayment({
        book_session_id: book_session_id,
        idempotentKey: idempotentKey,
      })
      return response
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const usePreCheckout = (
  paymentHandler: ReturnType<PaymentHandlerStrategy>,
) => {
  const { setErrorPayment } = usePaymentStore((state) => ({
    setErrorPayment: state.setErrorPayment,
  }))
  const {
    data: preCheckoutData,
    isFetching: isPreCheckoutPaymentFetching,
    refetch: fetchPreCheckout,
    error: errorPreCheckout,
    isError: isErrorPreCheckout,
  } = useQuery<IPreCheckoutResult, AxiosError>({
    queryKey: [`pre-checkout-${paymentHandler.itemType}`],
    queryFn: async () => {
      try {
        return await paymentHandler.preCheckout()
      } catch (error) {
        setErrorPayment(error, 'pre-checkout')
        throw error
      }
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  })

  return {
    preCheckoutData,
    isPreCheckoutPaymentFetching,
    fetchPreCheckout,
    errorPreCheckout,
    isErrorPreCheckout,
  }
}
