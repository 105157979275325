import { useTranslation } from 'react-i18next'
import { useState, useEffect, MouseEventHandler } from 'react'

import {
  LabelWrapper,
  StyledBoldWhiteTitle,
  StyledInfoIcon,
  StyledInfoLabel,
} from '../styled'
import ActionButton from 'components/commons/Button/Action'
import PaymentInstructionsQrBody from './PaymentInstructionsQrBody'
import PaymentInstructionsVaBody from './PaymentInstructionsVaBody'
import ExpiryTimeBanner from 'components/domains/Content/ExpiryTimeBanner'
import TimeoutPaymentBanner from 'components/commons/TimeoutPaymentBanner'

import { sendLog } from 'helpers/log'
import isEmpty from 'helpers/is-empty'
import { useFeatureFlags } from 'helpers/feature-flag'
import handleCustomDateWithTimeZone from 'helpers/handleCustomDateWithTimeZone'
import { usePaymentInstruction } from 'hooks/domains/Payment/usePaymentInstruction'

import appConfig from 'configs/app'
import { PaymentMethodCode } from 'types/payment'
import {
  paymentConfirmation,
  paymentConfirmationV2,
} from 'services/payment'

export interface PaymentInstructionProps {
  invoiceId: string
  paymentMethodCode: PaymentMethodCode
  idempotentKey: string
  isDisableActionButton?: boolean
  onClickActionButton: Function
  useV2?: boolean
  isPageView?: boolean
}

function PaymentInstruction({
  invoiceId,
  paymentMethodCode,
  idempotentKey,
  isDisableActionButton = false,
  onClickActionButton,
  useV2,
  isPageView = false,
}: PaymentInstructionProps) {
  const { t } = useTranslation()

  const enableNativePayment = useFeatureFlags('native-payment')
  const finishPayment = useFeatureFlags('finish-payment-bca')
  const [isPendingNotifOpen, setIsPendingNotifOpen] = useState(false)
  const [isTimerActive, setTimerActive] = useState(false)
  const [isTimeoutPaymentShow, setTimeoutPaymentShow] =
    useState(false)

  const { paymentInstructionList } = usePaymentInstruction({
    invoiceId,
    paymentMethodCode,
    idempotentKey,
    useV2,
  })

  const vaConfirmationHandler = async () => {
    try {
      if (enableNativePayment)
        await paymentConfirmationV2({
          invoice: paymentInstructionList.invoice_id,
        })
      else
        await paymentConfirmation({
          invoice: paymentInstructionList.invoice_id,
        })
    } catch (error) {
      sendLog(`Error: ${error?.code} - ${error?.message}`)
    }
  }
  const handleTimerFinished = () => {
    setTimeoutPaymentShow(true)
    setIsPendingNotifOpen(false)
  }

  useEffect(() => {
    setTimerActive(paymentInstructionList?.is_timer_active)
    if (paymentInstructionList?.is_timer_active) {
      setIsPendingNotifOpen(true)
    }
  }, [paymentInstructionList])

  const handleRenderBodyPaymentInstruction = () => {
    if (useV2) {
      switch (paymentInstructionList?.payment_method_group_code) {
        case 'VA':
          return (
            <PaymentInstructionsVaBody
              paymentInstructionList={paymentInstructionList}
              isPageView={isPageView}
            />
          )
        default:
          return (
            <PaymentInstructionsQrBody
              paymentInstructionList={paymentInstructionList}
              showQRCode={
                !isEmpty(paymentInstructionList?.qr_detail?.qr_string)
              }
              showTitle
            />
          )
      }
    } else {
      return (
        <PaymentInstructionsVaBody
          paymentInstructionList={paymentInstructionList}
        />
      )
    }
  }
  return (
    <>
      {isTimerActive ? (
        <>
          <ExpiryTimeBanner
            isOpen={isPendingNotifOpen}
            expiryDate={paymentInstructionList?.expiry_date}
            remainingTime={paymentInstructionList?.time_remaining}
            message={paymentInstructionList?.expiry_message}
            sx={{
              margin: '8px 0px',
            }}
            onHandleTimerFinished={handleTimerFinished}
          />
          <TimeoutPaymentBanner
            isOpen={isTimeoutPaymentShow}
            desc={paymentInstructionList?.expiry_message}
          />
        </>
      ) : (
        <LabelWrapper>
          <StyledInfoIcon />
          <StyledInfoLabel>
            {t('vaPaymentInstructionWidgetTextSpan1')}{' '}
            <StyledBoldWhiteTitle>
              {handleCustomDateWithTimeZone(
                paymentInstructionList?.expiry_date,
              )}
            </StyledBoldWhiteTitle>
          </StyledInfoLabel>
        </LabelWrapper>
      )}
      {handleRenderBodyPaymentInstruction()}
      {paymentInstructionList && (
        <>
          {finishPayment &&
            appConfig.environment !== 'production' &&
            paymentInstructionList?.payment_method_group_code !==
              'E-WALLET' && (
              <ActionButton
                sx={{
                  display: 'block',
                  margin: '20px auto',
                }}
                data-testid="b-payment-complete-your-payment"
                onClick={vaConfirmationHandler}
              >
                {t('key_complete_your_payment')}
              </ActionButton>
            )}
          <ActionButton
            id="b-wallet-check-payment-status"
            sx={{
              padding: '12px 24px',
              width: '100%',
            }}
            disabled={isDisableActionButton}
            onClick={
              onClickActionButton as MouseEventHandler<HTMLButtonElement>
            }
          >
            {t('vaPaymentInstructionWidgetButton')}
          </ActionButton>
        </>
      )}
    </>
  )
}
export default PaymentInstruction
