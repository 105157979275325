import { styled } from '@mui/material'
import Typography from '../Typography'

const Subtitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.normalRegular,
  color: theme.palette.text.secondary,
  textAlign: 'center',
  letterSpacing: '0.024px',
  marginTop: '16px',

  [theme.breakpoints.down('sm')]: {
    lineHeight: '21px',
    letterSpacing: '0.021px',
  },
}))

const RequestIdentifierLabel = (props) => {
  return (
    <Subtitle id="c-common-request-id-label" {...props}>
      Request ID : {props.id}
    </Subtitle>
  )
}

export default RequestIdentifierLabel
