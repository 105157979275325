import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FileCopyRounded } from '@mui/icons-material'
import { ClickAwayListener, Tooltip } from '@mui/material'
import Box from 'components/commons/Box'
import Stack from 'components/commons/Stack'
import { StyledIconImg } from 'containers/domains/Wallet/DirectPaymentModal/styled'
import {
  CopyWrapperItems,
  StyledBoldWhite,
  StyledCopyTitle,
} from '../../styled'
import PaymentInstructionTab from '../PaymentInstructionTab'

import { sendLog } from 'helpers/log'
import { copyToClipboard } from 'helpers/clipboard'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import { IPaymentInstructionList } from 'types/payment'

export default function PaymentInstructionsVaBody({
  paymentInstructionList,
  isPageView = false,
}: {
  paymentInstructionList: IPaymentInstructionList
  isPageView?: boolean
}) {
  const { t } = useTranslation()
  const [openCopyVa, setOpenCopyVa] = useState(false)
  const [openCopyTotal, setOpenCopyTotal] = useState(false)

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        paymentInstructionList?.total.toString(),
      )
      setOpenCopyTotal(true)
    } catch (error) {
      sendLog(error)
    }
  }

  return (
    <>
      <CopyWrapperItems>
        <StyledCopyTitle>
          {t('vaPaymentInstructionDetailWidgetVirtualNameLabel')}
        </StyledCopyTitle>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ marginBottom: '16px' }}
        >
          <StyledBoldWhite sx={{ marginBottom: 0 }}>
            {paymentInstructionList?.va_name ||
              paymentInstructionList?.va_detail?.va_name}
          </StyledBoldWhite>
          {paymentInstructionList?.icon && (
            <Box
              sx={{
                width: '40px',
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: '10px',
              }}
            >
              <StyledIconImg
                sx={{
                  width: '100%',
                  padding: '0px !important',
                }}
                src={paymentInstructionList.icon}
              />
            </Box>
          )}
        </Stack>
        <StyledCopyTitle>
          {t('vaPaymentInstructionDetailWidgetVirtualNumberLabel')}
        </StyledCopyTitle>
        <StyledBoldWhite>
          {paymentInstructionList?.va ||
            paymentInstructionList?.va_detail?.va}
          <ClickAwayListener onClickAway={() => setOpenCopyVa(false)}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => setOpenCopyVa(false)}
              open={openCopyVa}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Berhasil Disalin!"
            >
              <FileCopyRounded
                id="b-payment-copy-va-number"
                onClick={() => {
                  copyToClipboard(
                    paymentInstructionList?.va ||
                      paymentInstructionList?.va_detail?.va,
                    () => setOpenCopyVa(true),
                  )
                }}
                sx={(theme) => ({
                  marginLeft: 'auto',
                  width: '20px',
                  height: '20px',
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                })}
              />
            </Tooltip>
          </ClickAwayListener>
        </StyledBoldWhite>
        <StyledCopyTitle>
          {t('vaPaymentInstructionDetailWidgetTotalLabel')}
        </StyledCopyTitle>
        <StyledBoldWhite>
          Rp{NumberBaseFormatter(paymentInstructionList?.total)}
          <ClickAwayListener
            onClickAway={() => setOpenCopyTotal(false)}
          >
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => setOpenCopyTotal(false)}
              open={openCopyTotal}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Berhasil Disalin!"
            >
              <FileCopyRounded
                onClick={handleCopy}
                id="b-payment-copy-va-total-price"
                sx={(theme) => ({
                  marginLeft: 'auto',
                  width: '20px',
                  height: '20px',
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                })}
              />
            </Tooltip>
          </ClickAwayListener>
        </StyledBoldWhite>
      </CopyWrapperItems>
      {paymentInstructionList && (
        <PaymentInstructionTab
          paymentInstructionList={paymentInstructionList}
          isPageView={isPageView}
        />
      )}
    </>
  )
}
