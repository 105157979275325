import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { INotificationStore } from './Notification/interface'
import { createNotificationStore } from './Notification'

import { IPromoterStore } from './Promoter/interface'
import { createPromoterStore } from './Promoter'

import { IAddToCartStore } from './Cart/interface'
import { createAddToCartStore } from './Cart'
import { createSupportedCountryStore } from './SupportedCountry'
import { ISupportedCountryStore } from './SupportedCountry/interface'
import { createMultipleLanguageStore } from './MultipleLanguageDrawer'
import IMultipleLanguageModel from './MultipleLanguageDrawer/interface'
import { createCustomThemeStore } from './CustomTheme'
import ICustomTheme from './CustomTheme/interface'

export const useNotificationStore = create<INotificationStore>()(
  persist(
    (...a) => ({
      ...createNotificationStore(...a),
    }),
    {
      name: 'notification-storage',
      partialize(state) {
        return {
          hasNewNotification:
            state.totalUnreadInformationNotification > 0 &&
            state.totalUnreadTransactionNotification > 0,
        }
      },
    },
  ),
)

export const usePromoterStore = create<IPromoterStore>()((...a) => ({
  ...createPromoterStore(...a),
}))

export const useAddToCartStore = create<IAddToCartStore>()(
  (...a) => ({
    ...createAddToCartStore(...a),
  }),
)

export const useSupportedCountryStore =
  create<ISupportedCountryStore>()((...a) => ({
    ...createSupportedCountryStore(...a),
  }))

export const useMultipleLanguageStore =
  create<IMultipleLanguageModel>()((...a) => ({
    ...createMultipleLanguageStore(...a),
  }))

export const useCustomThemeStore = create<ICustomTheme>()(
  persist(
    (...a) => ({
      ...createCustomThemeStore(...a),
    }),
    {
      name: 'theme-store',
    },
  ),
)
