import { ThemeKeys } from 'constants/common'
import { useState, useEffect } from 'react'
import { useCustomThemeStore } from 'stores/commons'

import { getTheme } from 'theme'

const useCustomTheme = () => {
  const { partner } = useCustomThemeStore()

  const [theme, setTheme] = useState(getTheme(ThemeKeys.DEFAULT))

  useEffect(() => {
    setTheme(getTheme(partner as ThemeKeys))
  }, [partner])

  return theme
}

export default useCustomTheme
