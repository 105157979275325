import { useQuery } from '@tanstack/react-query'
import { usePaymentStore } from 'stores/domains/Payment'
import {
  ICheckoutData,
  ICheckoutParams,
  PaymentHandlerStrategy,
} from 'types/payment'
interface ICheckoutHooksParams extends ICheckoutParams {
  paymentHandler?: ReturnType<PaymentHandlerStrategy>
  checkoutCallback?: (response: ICheckoutData) => void
}

export const useCheckout = ({
  paymentHandler,
  checkoutCallback = () => {},
  paymentMethodCode,
  paymentId,
  invoiceId,
  username,
  voucherCode = '',
  promoterToken,
  cardNumber,
  authId,
  count,
  tokenId,
  productUid,
  phoneNumber,
  acquirer,
}: ICheckoutHooksParams = {}) => {
  const { setErrorPayment } = usePaymentStore((state) => ({
    setErrorPayment: state.setErrorPayment,
  }))
  const {
    isFetching: isCheckoutPaymentFetching,
    refetch: fetchCheckout,
    data: checkoutData,
    isError: isErrorCheckout,
    error: errorCheckout,
  } = useQuery<ICheckoutData>({
    queryKey: [`checkout-${paymentHandler.itemType}`],
    queryFn: async () => {
      try {
        const checkoutResponse = await paymentHandler.checkout({
          voucherCode,
          paymentMethodCode,
          paymentId,
          invoiceId,
          username,
          promoterToken,
          cardNumber,
          authId,
          count,
          tokenId,
          productUid,
          phoneNumber,
          acquirer,
        })

        checkoutCallback(checkoutResponse)
        return checkoutResponse
      } catch (error) {
        if (error?.response?.status === 500) {
          const errorPayment = { ...error }
          errorPayment.response.data.code = 'FAIL_CHECKOUT'
          setErrorPayment(errorPayment)
        }

        if (
          paymentMethodCode === 'TIPTIP_COIN' &&
          error?.response?.data?.code === 'INSUFFICIENT_BALANCE'
        ) {
          // When the payment method is TIPTIP_COIN and the balance is low,
          // the error modal with the code INSUFFICIENT_BALANCE_COIN should be displayed.
          const errorPayment = { ...error }
          errorPayment.response.data.code =
            'INSUFFICIENT_BALANCE_COIN'
          setErrorPayment(errorPayment)
        } else {
          setErrorPayment(error)
        }
        throw error
      }
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  })

  return {
    checkoutData,
    isCheckoutPaymentFetching,
    fetchCheckout,
    isErrorCheckout,
    errorCheckout,
  }
}
