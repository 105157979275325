import MainLogo from 'assets/logos/logo-mobile.svg'
import {
  ImageWrapper,
  VoucherButton,
  VoucherCardWrapper,
  VoucherContent,
  VoucherContentWrapper,
  VoucherDesc,
  VoucherDescText,
  VoucherHeader,
  VoucherName,
  DescWrapper,
} from 'components/domains/User/VoucherCard/styled'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import { useTheme } from '@mui/material/styles'
import dateFormatter from 'helpers/formats/date'
import appConfig from 'configs/app'
import trackEvent from 'trackers'
import { useTranslation } from 'react-i18next'
import Box from 'components/commons/Box'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import { IVoucherCard } from 'types/campaign'

interface IVoucherCardProps {
  voucherItem: IVoucherCard
  voucherChecker?: Function
  voucherDetailsHandler?: Function
  isLoading: boolean
  trackerData?: any
}

const VoucherCard = ({
  voucherItem,
  voucherChecker,
  voucherDetailsHandler,
  isLoading,
  trackerData,
}: IVoucherCardProps) => {
  const { t } = useTranslation()

  const theme = useTheme()
  return (
    <VoucherCardWrapper
      data-testid="b-payment-voucher-card-wrapper"
      onClick={() => {
        if (voucherDetailsHandler) {
          trackEvent.paymentCart(`select_voucher`, trackerData, {
            voucher_code: voucherItem?.rule,
          })
          !isLoading ? voucherDetailsHandler(voucherItem) : ''
        }
      }}
      sx={{
        maxWidth: 448,
      }}
    >
      <VoucherContent>
        <ImageWrapper
          sx={{ position: 'relative' }}
          urlBg={`${appConfig.assetBaseUrl}/voucher/voucher_2desc_${
            voucherItem.is_grey_out ? 'disabled' : 'active'
          }.png`}
        >
          <VoucherContentWrapper>
            <VoucherHeader>
              <VoucherName>{voucherItem.name}</VoucherName>
              <MainLogo />
            </VoucherHeader>
            <DescWrapper>
              <Box>
                {voucherItem?.short_description !== '' && (
                  <VoucherDesc>
                    <ErrorOutlineRoundedIcon
                      style={{
                        color: theme.palette.tiptip[500],
                        marginRight: 3,
                      }}
                    />
                    <VoucherDescText>
                      {voucherItem.short_description}
                    </VoucherDescText>
                  </VoucherDesc>
                )}
                {voucherItem.max_discount_amount && (
                  <VoucherDesc>
                    <MonetizationOnOutlinedIcon
                      style={{
                        color: theme.palette.tiptip[500],
                        marginRight: 3,
                      }}
                    />
                    <VoucherDescText>
                      {t('key_max_discount')}
                      {' Rp '}
                      {NumberBaseFormatter(
                        voucherItem.max_discount_amount,
                      )}
                    </VoucherDescText>
                  </VoucherDesc>
                )}
                {voucherItem?.expired && (
                  <VoucherDesc>
                    <AccessTimeIcon
                      style={{
                        color: theme.palette.tiptip[500],
                        marginRight: 3,
                      }}
                    />
                    <VoucherDescText>
                      {t('voucherInboxScreenExpired')}{' '}
                      {dateFormatter({
                        date: voucherItem.expired,
                        format: 'D MMMM YYYY',
                      })}
                    </VoucherDescText>
                  </VoucherDesc>
                )}
              </Box>
            </DescWrapper>
            <VoucherButton
              disabled={isLoading || voucherItem.is_grey_out}
              variant="contained"
              fullWidth
              onClick={(event) => {
                voucherChecker(voucherItem?.rule, true)
                event.stopPropagation()
              }}
              data-testid={'voucher-button'}
            >
              {t('voucherInboxScreenVoucherButton')}
            </VoucherButton>
          </VoucherContentWrapper>
        </ImageWrapper>
      </VoucherContent>
    </VoucherCardWrapper>
  )
}

export default VoucherCard
