import { useQuery } from '@tanstack/react-query'
import {
  getCheckoutInstruction,
  getCheckoutInstructionV2,
} from 'services/payment'
import { usePaymentStore } from 'stores/domains/Payment'
import {
  IPaymentInstructionList,
  PaymentMethodCode,
} from 'types/payment'

export const usePaymentInstruction = ({
  invoiceId,
  paymentMethodCode,
  idempotentKey,
  useV2 = false,
}: {
  invoiceId: string
  paymentMethodCode?: PaymentMethodCode
  idempotentKey: string
  useV2?: boolean
}) => {
  const { setErrorPayment } = usePaymentStore((state) => ({
    setErrorPayment: state.setErrorPayment,
  }))
  const {
    data: paymentInstructionList,
    isError: isErrorPaymentInstruction,
    error: errorPaymentInstruction,
  } = useQuery<IPaymentInstructionList>({
    queryKey: ['payment-instruction', invoiceId, paymentMethodCode],
    queryFn: async () => {
      try {
        if (useV2) {
          return await getCheckoutInstructionV2({
            invoice_id: invoiceId,
            idempotentKey,
          })
        } else {
          return await getCheckoutInstruction({
            invoice_id: invoiceId,
            payment_method: paymentMethodCode,
            idempotentKey,
          })
        }
      } catch (error) {
        setErrorPayment(error)
        throw error
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
  })

  return {
    paymentInstructionList,
    isErrorPaymentInstruction,
    errorPaymentInstruction,
  }
}
