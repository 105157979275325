import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import InfoIcon from '@mui/icons-material/InfoOutlined'
import { useMediaQuery, useTheme } from '@mui/material'

import {
  StyledContainer,
  StyledStack,
  CoinInfoContainer,
  CoinIconContainer,
  CoinQuantityLabel,
  CoinPriceContainer,
  CoinPriceLabel,
  PriceCurrency,
  StyledDivider,
  TotalPaymentLabel,
  CoinLabelDiscount,
  CoinValueLabel,
  StyledTitleDetailPayment,
  StyledNote,
  StyledCoin,
} from 'components/domains/Wallet/DirectPaymentDrawerContent/styled'
import Box from 'components/commons/Box'
import { StyledHelpIcon } from 'containers/domains/Wallet/DirectPaymentModal/styled'
import Stack from 'components/commons/Stack'

import NumberBaseFormatter from 'helpers/formats/number-base-format'
import isEmpty from 'helpers/is-empty'

import { IInstallmentFormData, IPreCheckoutData } from 'types/payment'
import { IPartnerConfig } from 'types/partner'

export interface IPaymentDetailSectionProps {
  setIsAdditionalFeesOpen?: Function
  selectedPaymentMethodIndex?: number
  isAppliedVoucher?: boolean
  preCheckoutData: IPreCheckoutData
  isCoinMethod?: boolean
  selectedInstallmentData?: IInstallmentFormData
  isPageView?: boolean
  visibility?: IPartnerConfig
}

export default function PaymentDetailSection({
  setIsAdditionalFeesOpen,
  selectedPaymentMethodIndex,
  isAppliedVoucher,
  preCheckoutData,
  isCoinMethod = false,
  selectedInstallmentData,
  isPageView = true,
  visibility,
}: IPaymentDetailSectionProps) {
  const SUBSCRIPTION_MONTH_DURATION = 30
  const { t } = useTranslation()
  const theme = useTheme()
  const isDesktopResolution = useMediaQuery(
    theme.breakpoints.up('sm'),
  )

  const getAdditionalFeeLabel = () => {
    // If admin_fee_title is exist on selected payment method, will use admin_fee_title from response endpoint
    if (
      !preCheckoutData?.use_default_admin_fee_title &&
      !isEmpty(
        preCheckoutData?.payment_method?.[selectedPaymentMethodIndex]
          ?.admin_fee_title,
      )
    )
      return preCheckoutData?.payment_method?.[
        selectedPaymentMethodIndex
      ]?.admin_fee_title
    return t('paymentDetailWidgetAdditionalFeeLabel')
  }

  // variable to determine this payment detail component is openend for top up coin or not
  const isCoinPaymentDetail = Boolean(preCheckoutData?.coins?.length)

  const voucherPrice = useMemo(() => {
    if (preCheckoutData?.payment_method?.length)
      return {
        totalPriceBeforeDiscount:
          preCheckoutData?.payment_method?.[
            selectedPaymentMethodIndex
          ]?.price?.total_price_before_discount || 0,
        totalPrice:
          preCheckoutData?.payment_method?.[
            selectedPaymentMethodIndex
          ]?.price?.total_price || 0,
      }
    return {
      totalPriceBeforeDiscount:
        preCheckoutData?.payment_info?.total_price_before_discount ||
        0,
      totalPrice: preCheckoutData?.payment_info?.total || 0,
    }
  }, [preCheckoutData])

  const TotalPaymentSection = () => {
    return (
      <CoinPriceContainer
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        style={{ display: isDesktopResolution ? 'flex' : 'block' }}
        data-testid="coin-price-container"
      >
        {isAppliedVoucher && !isCoinMethod ? (
          <>
            <CoinLabelDiscount data-testid="c-wallet-voucher-total-price-before-discount">
              Rp
              {NumberBaseFormatter(
                voucherPrice.totalPriceBeforeDiscount,
              )}
            </CoinLabelDiscount>
            <CoinValueLabel
              id={`c-wallet-total-price-value`}
              data-testid="c-wallet-total-price-value"
            >
              <PriceCurrency>Rp</PriceCurrency>
              {NumberBaseFormatter(voucherPrice.totalPrice)}
            </CoinValueLabel>
          </>
        ) : (
          <Box style={{ display: 'block' }}>
            <CoinValueLabel id={`c-wallet-total-price-value`}>
              <PriceCurrency>Rp</PriceCurrency>
              {isCoinPaymentDetail
                ? NumberBaseFormatter(
                    preCheckoutData?.payment_method?.[
                      selectedPaymentMethodIndex
                    ].total,
                  )
                : NumberBaseFormatter(
                    preCheckoutData?.payment_method
                      ? preCheckoutData?.payment_method?.[
                          selectedPaymentMethodIndex
                        ]?.price?.total_price
                      : preCheckoutData?.payment_info?.total,
                  )}
            </CoinValueLabel>
            {isCoinMethod && !isCoinPaymentDetail && (
              <CoinValueLabel>
                (
                <PriceCurrency>
                  <StyledCoin />
                </PriceCurrency>
                {NumberBaseFormatter(
                  preCheckoutData?.payment_method?.[
                    selectedPaymentMethodIndex
                  ].coin.total_price,
                )}
                )
              </CoinValueLabel>
            )}
          </Box>
        )}
      </CoinPriceContainer>
    )
  }

  return (
    <StyledContainer>
      {!isEmpty(preCheckoutData?.session_id) ? (
        <StyledNote>
          <InfoIcon
            sx={{
              color: theme.palette.info.main,
              fontSize: '18px',
              marginRight: '6px',
            }}
          />
          {t('key_complete_the_payment_immidiate')}
        </StyledNote>
      ) : (
        <StyledTitleDetailPayment>
          {t('paymentConfirmationBottomSheetPaymentSummary')}
        </StyledTitleDetailPayment>
      )}

      <StyledStack
        direction="column"
        justifyContent="space-between"
        spacing={2}
      >
        {
          <>
            <Stack spacing={2}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Stack direction={'row'}>
                  <CoinQuantityLabel>
                    {preCheckoutData?.duration ? (
                      <>
                        {preCheckoutData?.title} <br />{' '}
                        {Math.floor(
                          preCheckoutData?.duration /
                            SUBSCRIPTION_MONTH_DURATION,
                        )}
                        {` ${t('monthLabel')}`}
                      </>
                    ) : (
                      t('paymentDetailWidgetBasePriceLabel')
                    )}
                  </CoinQuantityLabel>
                </Stack>
                <CoinPriceLabel id="c-payment-price-value-label">
                  <PriceCurrency>Rp</PriceCurrency>
                  {isCoinPaymentDetail
                    ? NumberBaseFormatter(
                        preCheckoutData?.payment_method?.[
                          selectedPaymentMethodIndex
                        ]?.sub_total,
                      )
                    : NumberBaseFormatter(
                        preCheckoutData?.payment_method?.length
                          ? preCheckoutData?.payment_method?.[
                              selectedPaymentMethodIndex
                            ]?.price?.sub_total
                          : preCheckoutData?.base_price,
                      )}
                </CoinPriceLabel>
              </Stack>

              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Stack direction={'row'} alignItems={'center'}>
                  <CoinQuantityLabel>
                    {getAdditionalFeeLabel()}
                  </CoinQuantityLabel>
                  {preCheckoutData?.payment_method?.length && (
                    <StyledHelpIcon
                      id="b-wallet-help-icon-additional-fee"
                      onClick={() => {
                        setIsAdditionalFeesOpen(true)
                      }}
                      data-testid="b-wallet-help-icon-additional-fee"
                    />
                  )}
                </Stack>
                <CoinPriceLabel id="c-payment-admin-fee-value-label">
                  <PriceCurrency>Rp</PriceCurrency>
                  {isCoinPaymentDetail
                    ? NumberBaseFormatter(
                        preCheckoutData?.payment_method?.[
                          selectedPaymentMethodIndex
                        ].transaction_fee,
                      )
                    : NumberBaseFormatter(
                        preCheckoutData?.payment_method
                          ? preCheckoutData?.payment_method?.[
                              selectedPaymentMethodIndex
                            ]?.price?.total_additional_fee
                          : preCheckoutData?.price_detail,
                      )}
                </CoinPriceLabel>
              </Stack>

              {preCheckoutData?.payment_info && (
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Stack direction={'row'}>
                    <CoinQuantityLabel>PPN</CoinQuantityLabel>
                  </Stack>
                  <CoinPriceLabel id="c-payment-tax-value-label">
                    <PriceCurrency>Rp</PriceCurrency>
                    {NumberBaseFormatter(
                      preCheckoutData?.payment_info.tax,
                    )}
                  </CoinPriceLabel>
                </Stack>
              )}

              {/* Section voucher info */}
              {isAppliedVoucher &&
                !isEmpty(
                  preCheckoutData?.payment_method?.[
                    selectedPaymentMethodIndex
                  ]?.voucher_info,
                ) && (
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Stack direction={'row'} alignItems={'center'}>
                      <CoinQuantityLabel
                        sx={{ color: theme.palette.success.main }}
                      >
                        {
                          preCheckoutData?.payment_method?.[
                            selectedPaymentMethodIndex
                          ]?.voucher_info
                        }
                      </CoinQuantityLabel>
                    </Stack>
                    <CoinPriceLabel
                      id="c-payment-voucher-value-label"
                      sx={{ color: theme.palette.success.main }}
                    >
                      <PriceCurrency
                        sx={{ color: theme.palette.success.main }}
                      >
                        -Rp
                      </PriceCurrency>
                      {NumberBaseFormatter(
                        preCheckoutData?.payment_method?.[
                          selectedPaymentMethodIndex
                        ]?.price?.voucher_discount,
                      )}
                    </CoinPriceLabel>
                  </Stack>
                )}
            </Stack>

            {/* section installment */}
            {selectedInstallmentData?.count > 1 && (
              <>
                <StyledTitleDetailPayment variant="mediumBold">
                  {t('key_installment_details')}
                </StyledTitleDetailPayment>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '16px',
                  }}
                >
                  <CoinQuantityLabel>
                    {t('key_payment_period')}
                  </CoinQuantityLabel>
                  <CoinPriceLabel>
                    {selectedInstallmentData?.count} {t('month')}
                  </CoinPriceLabel>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <CoinQuantityLabel>
                    {t('key_monthly_installments')}
                  </CoinQuantityLabel>
                  <CoinPriceLabel>
                    Rp
                    {NumberBaseFormatter(
                      selectedInstallmentData?.installment_amount,
                    )}
                  </CoinPriceLabel>
                </Box>
              </>
            )}

            {isDesktopResolution && <StyledDivider />}
          </>
        }
        {(visibility?.showTotalPayment ||
          (visibility?.useThemePartner && isDesktopResolution) ||
          !isPageView) && (
          <CoinInfoContainer
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
          >
            <CoinIconContainer
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <TotalPaymentLabel>
                {t('paymentConfirmationBottomSheetTotalV2')}
              </TotalPaymentLabel>
            </CoinIconContainer>
            {TotalPaymentSection()}
          </CoinInfoContainer>
        )}
      </StyledStack>
    </StyledContainer>
  )
}
