import Box from 'components/commons/Box'
import ActionButton from 'components/commons/Button/Action'
import StateImage from 'components/commons/StateImage'
import {
  ItemsLabelVa,
  ItemsLabelVaBold,
  VaDesc,
  VaTitleLabel,
  WrapperItemsPending,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import { getIdToken } from 'helpers/auth'
import dateFormatter from 'helpers/formats/date'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import { useTranslation } from 'react-i18next'
import { StyledCoin } from '../DirectPaymentDrawerContent/styled'
export interface ISuccessStateProps {
  vaStatus: {
    invoice_id: string
    payment_method_code: string
    status: string
    paid_at: string
    checkout_at: string
    payment_method_name: string
    total: number
    ticket: string
  }
  itemType?: string
  handleClose: Function
  isOpen?: boolean
  isPaymentLoading?: boolean
}

export default function SuccessVaPaymentContent({
  vaStatus,
  itemType,
  handleClose,
  isPaymentLoading,
}: ISuccessStateProps) {
  const { t } = useTranslation()
  const renderDescription = () => {
    if (itemType === 'event' && !getIdToken()) {
      return t('key_thankyou_your_purchase_event')
    } else {
      return t('key_thankyou_your_purchase_content')
    }
  }
  const renderPaymentMethod = () => {
    if (vaStatus.payment_method_code === 'TIPTIP_COIN') {
      return t('key_tiptip_coin')
    } else if (
      vaStatus.payment_method_name === 'Other Payment Method' ||
      vaStatus.payment_method_name === 'Metode Pembayaran Lain' ||
      vaStatus.payment_method_code === 'OTHER_METHOD'
    ) {
      return (
        vaStatus.payment_method_name +
        ` - ` +
        vaStatus.payment_method_code
      )
    } else {
      return vaStatus.payment_method_name
    }
  }
  return (
    <>
      <Box>
        <StateImage
          type="success-withdraw"
          sx={{
            marginBottom: '16px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
        <VaTitleLabel>
          {t('transactionVaStatusWidgetPaidTitle')}
        </VaTitleLabel>
        <VaDesc>{renderDescription()}</VaDesc>
        <WrapperItemsPending>
          <ItemsLabelVa>
            {t('vaPaymentInstructionDetailWidgetTotalLabel')}
            <ItemsLabelVaBold>
              {vaStatus.payment_method_code === 'TIPTIP_COIN' ? (
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <StyledCoin />
                  {vaStatus?.total}
                </Box>
              ) : (
                `Rp${NumberBaseFormatter(vaStatus?.total)}`
              )}
            </ItemsLabelVaBold>
          </ItemsLabelVa>
          <ItemsLabelVa>
            {t('transactionVAStatusDetailPaidWidgetPaidAtLabel')}
            <ItemsLabelVaBold>
              {dateFormatter({
                date: vaStatus?.paid_at,
                format: 'DD MMMM YYYY, HH:mm',
              })}
            </ItemsLabelVaBold>
          </ItemsLabelVa>
          <ItemsLabelVa>
            {t(
              'transactionVAStatusDetailPaidWidgetPaymentMethodNameLabel',
            )}
            <ItemsLabelVaBold>
              {renderPaymentMethod()}
            </ItemsLabelVaBold>
          </ItemsLabelVa>
        </WrapperItemsPending>
      </Box>
      <ActionButton
        disabled={isPaymentLoading}
        sx={{
          padding: '12px 24px',
          width: '100%',
        }}
        onClick={() => {
          if (itemType === 'event' && !getIdToken()) {
            window.open(vaStatus.ticket, '_blank')
          } else {
            handleClose()
          }
        }}
      >
        {itemType === 'event' && !getIdToken()
          ? t('eventDetailsSeeTicketButton')
          : t('transactionVaStatusWidgetBtn')}
      </ActionButton>
    </>
  )
}
