import { ClickAwayListener, styled, Tooltip } from '@mui/material'
import Typography from '../Typography'
import { MouseEventHandler } from 'react'

export const StyledTextLink = styled(Typography)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.blue[500],
  textDecorationColor: theme.palette.blue[500],
  fontWeight: 700,
  cursor: 'pointer',
}))

export interface IClickableTextWithTooltipProps {
  isOpenTooltip: boolean
  text: string
  callback?: Function
  toolTipCloser?: Function
}

const ClickableTextWithTooltip = ({
  isOpenTooltip,
  text,
  callback = () => {},
  toolTipCloser,
}: IClickableTextWithTooltipProps) => {
  return (
    <ClickAwayListener
      data-testid="b-common-away-listener"
      onClickAway={
        toolTipCloser as (event: MouseEvent | TouchEvent) => void
      }
    >
      <Tooltip
        data-testid="c-common-success-copy-tooltip"
        PopperProps={{
          disablePortal: true,
        }}
        onClose={
          toolTipCloser as (
            event: React.SyntheticEvent | Event,
          ) => void
        }
        open={isOpenTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Berhasil Disalin!"
      >
        <StyledTextLink
          data-testid="b-common-text-link"
          onClick={callback as MouseEventHandler<HTMLSpanElement>}
        >
          {text}
        </StyledTextLink>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default ClickableTextWithTooltip
