import { create, StateCreator } from 'zustand'
import {
  ErrorPaymentType,
  IPaymentStateDefault,
  IPaymentStore,
} from './interface'
import { IPaymentMethod, IPreCheckoutData } from 'types/payment'
import { flattenPaymentMethods } from 'utils/domains/Payment/paymentUtils'

export const INITIAL_STATE: IPaymentStateDefault = {
  isErrorPayment: false,
  errorPayment: undefined,
  preCheckoutWithVoucher: undefined,
  selectedPaymentMethod: undefined,
  errorType: null,
}

const createPaymentSlice: StateCreator<IPaymentStore> = (
  set,
  get,
) => ({
  ...INITIAL_STATE,
  setErrorPayment: (error, errorType?: ErrorPaymentType) => {
    set({
      isErrorPayment: true,
      errorPayment: error,
      errorType: errorType || null,
    })
  },
  resetErrorPayment: () => {
    set({
      isErrorPayment: false,
      errorPayment: undefined,
      errorType: null,
    })
  },
  setPreCheckoutWithVoucher: (data: IPreCheckoutData) => {
    if (data && !data?.payment_method) {
      const selectedPaymentMethod = get().selectedPaymentMethod
      const paymentMethodList = flattenPaymentMethods(
        data.payment_method_groups,
      )
      // Check if we already have selected payment method
      if (selectedPaymentMethod) {
        const specificPaymentMethod = paymentMethodList.find(
          (item) => item.code === selectedPaymentMethod.code,
        )
        // We need to re-store additional_info
        specificPaymentMethod.additional_info =
          selectedPaymentMethod.additional_info
        set({
          preCheckoutWithVoucher: {
            ...data,
            payment_method: [specificPaymentMethod],
          },
        })
      } else {
        set({
          preCheckoutWithVoucher: {
            ...data,
            payment_method: [...data.default_payment_methods],
          },
        })
      }
    } else {
      set({ preCheckoutWithVoucher: data })
    }
  },
  setSelectedPaymentMethod: (data: IPaymentMethod) => {
    set({ selectedPaymentMethod: data })
  },
})

export const usePaymentStore = create<IPaymentStore>()((...a) => ({
  ...createPaymentSlice(...a),
}))
