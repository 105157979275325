import { IconButton } from '@mui/material'
import {
  StyledButtonVoucher,
  StyledCheck,
  StyledInputVoucher,
  StyledInputWrapper,
} from '../VoucherInbox/styled'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'helpers/is-empty'

const VoucherInboxInput = ({
  checkVoucherData,
  isErrorCheckVoucher,
  onBlur,
  onClearInput,
  onSubmitButtonInput,
  isLoading,
}) => {
  const { t } = useTranslation()
  const [inputVoucherCode, setInputVoucherCode] = useState('')
  const [showSubmitInputButton, setShowSubmitInputButton] =
    useState(false)

  const handleShowAndHideSubmitInputButton = () => {
    setShowSubmitInputButton(!showSubmitInputButton)
  }

  useEffect(() => {
    if (isErrorCheckVoucher) {
      setInputVoucherCode('')
      setShowSubmitInputButton(false)
    }
  }, [isErrorCheckVoucher])

  return (
    <StyledInputWrapper>
      <StyledInputVoucher
        border={inputVoucherCode && checkVoucherData}
        isError={isErrorCheckVoucher}
        autoComplete="off"
        onChange={(e) =>
          setInputVoucherCode(e.target.value.toUpperCase())
        }
        onBlur={() => {
          onBlur()
          handleShowAndHideSubmitInputButton()
        }}
        onFocus={handleShowAndHideSubmitInputButton}
        value={inputVoucherCode}
        placeholder={t('voucherInboxScreenSearchBarHint')}
        startAdornment={
          inputVoucherCode && checkVoucherData && <StyledCheck />
        }
        endAdornment={
          inputVoucherCode && (
            <IconButton
              onMouseDown={(event) => {
                event.preventDefault()
              }}
              onClick={() => {
                onClearInput()
                setInputVoucherCode('')
              }}
            >
              <HighlightOffIcon />
            </IconButton>
          )
        }
      />
      {showSubmitInputButton && (
        <StyledButtonVoucher
          buttonVariant="filled"
          onMouseDown={(event) => {
            event.preventDefault()
          }}
          disabled={isEmpty(inputVoucherCode) || isLoading}
          onClick={() => onSubmitButtonInput(inputVoucherCode)}
          loading={isLoading}
        >
          {t('voucherInboxScreenButtonApplyVoucher')}
        </StyledButtonVoucher>
      )}
    </StyledInputWrapper>
  )
}

export default VoucherInboxInput
