import { Divider, OutlinedInput, styled } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import StateImage from 'components/commons/StateImage'
import Button from 'components/commons/Button'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import LoadingButton from 'components/commons/LoadingButton'

export const StyledInputWrapper = styled('span')({
  display: 'flex',
  width: '100%',
  position: 'relative',
})

type InputProps = {
  border?: any
  isError?: boolean
}
export const StyledErrorIcon = () => (
  <StateImage
    type="general-failed-load"
    sx={{
      marginBottom: '16px',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '55px',
    }}
  />
)
export const ErrorLabel = styled(Stack)(({ theme }) => ({
  ...theme.typography.h3,
  lineHeight: theme.typography.mediumRegular.lineHeight,
  textAlign: 'center',
  letterSpacing: '0.0015em',
  color: theme.palette.text.primary,
  marginBottom: 8,
}))
export const ErrorDesc = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.normalRegular,
  textAlign: 'center',
  marginBottom: 16,
}))
export const StyledInputVoucher = styled(OutlinedInput)<InputProps>(
  ({ border, isError, theme }) => ({
    color: theme.palette.text.primary,
    border:
      border && !isError
        ? `2px solid ${theme.palette.success.main}`
        : !border && isError
        ? `2px solid ${theme.palette.error.main}`
        : 'none',
    height: '44px',
    backgroundColor: theme.palette.background.tertiery,
    borderRadius: '6px',
    marginTop: '0px',
    marginBottom: '0px',
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '.MuiOutlinedInput-input': {
      color: theme.palette.text.primary,
      paddingRight: '57px',
      '&::placeholder': {
        color: theme.palette.text.secondary,
      },
    },
    width: '100%',
    input: {
      textTransform: 'uppercase',
    },
    'input::placeholder ': { textTransform: 'none' },
  }),
)
export const CoinInfoContainer = styled(Stack)({
  width: '100%',
  marginTop: 8,
})

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: 16,
  marginBottom: 16,
  background: theme.palette.background.tertiery,
  transform: ' matrix(1, 0, 0, -1, 0, 0)',
}))

export const StyledLink = styled('a')(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.blue[500],
  textDecoration: 'underline',
}))
export const PromoDesc = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.smallRegular,
}))
export const StyledSuccessMessage = styled(Stack)(({ theme }) => ({
  color: theme.palette.success.main,
  ...theme.typography.smallRegular,
}))
export const StyledErrorMessage = styled(Stack)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.error.main,
  ...theme.typography.smallRegular,
}))
export const StyledButtonVoucher = styled(LoadingButton)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    marginLeft: '8px',
    marginRight: 0,
    width: '93px',
    height: '44px',
    borderRadius: '8px',
  }),
)
export const StyledCheck = styled(CheckCircleIcon)(({ theme }) => ({
  fill: `${theme.palette.success.main} !important`,
  marginRight: '5px',
}))

export const MainTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.mediumBold,
  color: theme.palette.text.primary,
  marginBottom: '8px',
}))
export const SubTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.smallRegular,
  color: theme.palette.text.secondary,
  letterSpacing: '0.0015em',
  marginBottom: '8px',
}))
export const BackButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.gradient1,
  padding: '12px 9px',
  maxWidth: '592px',
  maxHeight: '39px',
  height: '100%',
  width: '100%',
  borderRadius: '8px',
  marginTop: '16px !important',
  '@media (max-width:400px)': {
    marginTop: '0px !important',
    padding: '12px 9px',
  },
}))
