import { useQuery } from '@tanstack/react-query'
import {
  getPaymentStatus,
  postDirectPaymentStatus,
} from 'services/payment'
import { IPaymentStatus, PaymentMethodCode } from 'types/payment'
import { AxiosError } from 'axios'
import { usePaymentStore } from 'stores/domains/Payment'
import { useFeatureFlags } from 'helpers/feature-flag'

export interface IPaymentStatusHooksParams {
  invoiceId: string
  paymentMethodCode?: PaymentMethodCode
  idempotentKey: string
  onCallbackSuccess?: (response: IPaymentStatus) => void
  onCallbackFailed?: (error: AxiosError) => void
}

export const usePaymentStatus = ({
  invoiceId,
  paymentMethodCode,
  idempotentKey,
  onCallbackSuccess = () => {},
  onCallbackFailed = () => {},
}: IPaymentStatusHooksParams) => {
  const enableNativePayment = useFeatureFlags('native-payment')
  const { setErrorPayment } = usePaymentStore((state) => ({
    setErrorPayment: state.setErrorPayment,
  }))
  const {
    data: paymentStatusData,
    refetch: fetchPaymentStatus,
    isError: isErrorPaymentStatus,
    error: errorPaymentStatus,
  } = useQuery<IPaymentStatus>({
    queryKey: ['check-payment-status'],
    queryFn: async () => {
      try {
        const paymentStatusResponse: IPaymentStatus =
          enableNativePayment
            ? await postDirectPaymentStatus({
                invoiceId: invoiceId,
                idempotentKey,
              })
            : await getPaymentStatus({
                invoiceId: invoiceId,
                idempotentKey,
                paymentMethod: paymentMethodCode,
              })
        onCallbackSuccess(paymentStatusResponse)
        return paymentStatusResponse
      } catch (error) {
        onCallbackFailed(error)
        setErrorPayment(error)
        throw error
      }
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  })

  return {
    paymentStatusData,
    fetchPaymentStatus,
    isErrorPaymentStatus,
    errorPaymentStatus,
  }
}
