import Box from 'components/commons/Box'
import Button from 'components/commons/Button'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import { useTranslation } from 'react-i18next'

const InstallmentCancellationModal = ({
  isOpen,
  onClose,
  onClickCancel,
  onClickContinue,
}) => {
  const { t } = useTranslation()
  return (
    <ModalDrawer
      title={t('key_installment_changes')}
      isOpen={isOpen}
      onClose={onClose}
      sxDrawerContainer={{ padding: '16px 0px' }}
      renderTitle
    >
      <Box>{t('key_change_voucher')}</Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 3,
        }}
      >
        <Button
          id="b-payment-installment-cancel"
          onClick={onClickCancel}
          size="large"
          fullWidth
          buttonVariant="outlined"
          sx={{
            borderRadius: '12px',
            width: '100%',
            height: '44px',
            marginTop: '16px',
          }}
        >
          {t('key_cancel')}
        </Button>
        <Button
          id="b-payment-installment-continue"
          onClick={onClickContinue}
          size="large"
          fullWidth
          sx={{
            borderRadius: '12px',
            width: '100%',
            height: '44px',
            marginTop: '16px',
          }}
        >
          {t('continueText')}
        </Button>
      </Box>
    </ModalDrawer>
  )
}

export default InstallmentCancellationModal
