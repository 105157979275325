import Box from 'components/commons/Box'
import ActionButton from 'components/commons/Button/Action'
import StateImage from 'components/commons/StateImage'
import {
  ItemsLabelVa,
  ItemsLabelVaBold,
  VaDesc,
  VaTitleLabel,
  WrapperItemsPending,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import dateFormatter from 'helpers/formats/date'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { IPaymentStatus } from 'types/payment'

interface IPendingPaymentModalProps {
  paymentStatus: IPaymentStatus
  onClickActionButton?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  description: string
  buttonTitle: string
  hideActionButton?: boolean
}

export default function PendingPaymentModal({
  paymentStatus,
  onClickActionButton,
  disabled = false,
  description,
  buttonTitle,
  hideActionButton = false,
}: IPendingPaymentModalProps) {
  const { t } = useTranslation()

  return (
    <Box>
      <StateImage
        type="pending-withdraw"
        sx={{
          marginBottom: '16px',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <VaTitleLabel>
        {t('transactionHistoryPendingStatus')}
      </VaTitleLabel>
      <VaDesc>{description}</VaDesc>
      <WrapperItemsPending>
        <ItemsLabelVa>
          {t('vaPaymentInstructionDetailWidgetTotalLabel')}
          <ItemsLabelVaBold>
            Rp{NumberBaseFormatter(paymentStatus?.total)}
          </ItemsLabelVaBold>
        </ItemsLabelVa>
        <ItemsLabelVa>
          {t('transactionVAStatusDetailPendingWidgetCheckoutAtLabel')}
          <ItemsLabelVaBold>
            {dateFormatter({
              date: paymentStatus?.checkout_at,
              format: 'DD MMM YYYY, HH:mm',
            })}
          </ItemsLabelVaBold>
        </ItemsLabelVa>
      </WrapperItemsPending>

      {!hideActionButton && (
        <ActionButton
          id="b-wallet-close-pending-payment"
          sx={{
            padding: '12px 24px',
            width: '100%',
          }}
          disabled={disabled}
          onClick={onClickActionButton}
        >
          {buttonTitle}
        </ActionButton>
      )}
    </Box>
  )
}
