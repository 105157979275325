import { Link, Tab, Tabs, alpha, styled } from '@mui/material'

import InfoIcon from '@mui/icons-material/Info'
import Button from 'components/commons/Button'
import Typography from 'components/commons/Typography'
import Box from 'components/commons/Box'

export const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '18px',
  margin: 'auto',
  marginRight: '9px',
  marginLeft: '0px',
}))
export const StyledBoldWhite = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: theme.typography.fontWeightBold,
  display: 'flex',
  marginBottom: 14,
}))
export const StyledBoldWhiteTitle = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightBold,
    display: 'inline',
    marginBottom: 14,
  }),
)

export const StyledInfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: theme.typography.fontWeightMedium,
}))

export const LabelWrapper = styled(Box)({
  display: 'flex',
  margin: '16px 0px',
})

export const CopyWrapperItems = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.tertiery,
  padding: 16,
  borderRadius: 5,
}))

export const StyledCopyTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '12px',
  fontWeight: theme.typography.fontWeightMedium,
  marginBottom: 5,
}))

export const StyledTabs = styled(Tabs)({
  '.css-1ebns2w-MuiButtonBase-root-MuiTab-root.Mui-selected ': {
    fontWeight: 700,
  },
  '.css-1ebns2w-MuiButtonBase-root-MuiTab-root': {
    fontWeight: 400,
  },
})

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: `${theme.palette.text.primary} !important`,
  fontWeight: 400,
  '&.MuiTab-root.Mui-selected': {
    fontWeight: 700,
  },
}))

export const StyledSteps = styled(Typography)(({ theme }) => ({
  ...theme.typography.normalBold,
  color: `${theme.palette.text.primary} !important`,
}))

export const StyledSubSteps = styled('li')(({ theme }) => ({
  color: `${theme.palette.text.primary} !important`,
  marginBottom: 5,
  ...theme.typography.normalRegular,
}))

export const StyledCopy = styled(Button)({
  padding: '2px 10px',
  WebkitBorderRadius: 20,
  marginTop: '-3px',
  marginLeft: 'auto',

  svg: {
    width: 14,
    height: 14,
    marginRight: 5,
  },
})

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.blue[500],
  textDecorationColor: theme.palette.blue[500],
  fontWeight: 700,
}))
export const StyledVaNumber = styled(Typography)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.blue[500],
  textDecorationColor: theme.palette.blue[500],
  fontWeight: 700,
  cursor: 'pointer',
}))

export const StyledBoldBlackTitle = styled(Typography)(
  ({ theme }) => ({
    ...theme.typography.mediumBold,
    display: 'flex',
    margin: '16px auto',
    alignContent: 'center',
    justifyContent: 'center',
  }),
)
export const StyledNormalTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.normalRegular,
  display: 'inline',
  lineHeight: '20px',
}))
export const StyledNormaBoldlTitle = styled(Typography)(
  ({ theme }) => ({
    ...theme.typography.normalBold,
    display: 'inline',
  }),
)

export const InstructionsWrapper = styled(Box)(({ theme }) => ({
  ...theme.typography.normalRegular,
  display: 'flex',
  margin: '12px',
  border: `1px solid  ${alpha(theme.palette.text.tertiery, 0.16)}`,
  borderRadius: '12px',
  padding: '12px',
}))

export const QrWrapper = styled(Box)({
  display: 'flex',
  margin: '24px auto',
  alignContent: 'center',
  justifyContent: 'center',

  svg: {
    width: '160px',
    height: '160px',
  },
})
export const BodyQrWrapper = styled(Box)({
  display: 'block',
  margin: '50px 34px',
})

export const ButtonWrapper = styled(Box)({
  display: 'flex',
  margin: '24px auto',
  alignContent: 'center',
  justifyContent: 'center',
})
