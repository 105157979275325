import { action, thunk } from 'easy-peasy'
import { getIdToken } from 'helpers/auth'
import get from 'lodash/get'
import ISessionDetailModel from 'stores/domains/Session/SessionDetail/interface'
import { sendLog } from 'helpers/log'

const SessionDetailModel: ISessionDetailModel = {
  isLoading: true,
  isError: false,
  errorMessage: '',
  error: null,
  sessionData: undefined,

  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload.errorMessage
    state.error = payload.error
  }),
  setSessionData: action((state, payload) => {
    state.sessionData = payload
    state.isError = false
    state.errorMessage = ''
  }),
  clearSessionData: action((state) => {
    state.sessionData = undefined
  }),
  getSessionDetail: thunk(
    async (actions, sessionID, { injections }) => {
      try {
        const currentUserToken = getIdToken()
        actions.setLoading(true)
        const { apiClient } = injections
        let sessionDetailResponse
        let currentUserDetailResponse

        if (currentUserToken) {
          sessionDetailResponse = await apiClient({
            url: `/session/v2/live/${sessionID}/detail`,
            method: 'GET',
            token: currentUserToken,
          })

          currentUserDetailResponse = await apiClient({
            url: `/session/v2/live/${sessionID}/user`,
            method: 'GET',
            token: currentUserToken,
          })

          actions.setSessionData({
            ...get(sessionDetailResponse, 'data.data', {}),
            ...get(currentUserDetailResponse, 'data.data', {}),
          })
        } else {
          sessionDetailResponse = await apiClient({
            url: `/session/guest/v2/live/${sessionID}/detail`,
            method: 'GET',
          })

          currentUserDetailResponse = await apiClient({
            url: `/session/guest/v2/live/${sessionID}/user`,
            method: 'GET',
          })
        }

        actions.setSessionData({
          ...get(sessionDetailResponse, 'data.data', {}),
          ...get(currentUserDetailResponse, 'data.data', {}),
        })
        actions.setLoading(false)
      } catch (error) {
        sendLog(
          `Failed to load session detail for ${sessionID} with error: ${error.message}`,
        )
        if (get(error, 'response.data.code') === 'FAIL') {
          actions.setError({
            errorMessage: get(error, 'response.data.errors', [
              'unknown error',
            ]).join(','),
            error,
          })
        } else {
          actions.setError({
            errorMessage: get(error, 'response.data.message'),
            error,
          })
        }
      }
    },
  ),
  getSessionDetailForGuest: thunk(
    async (actions, sessionID, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const sessionDetailResponse = await apiClient({
          url: `/session/guest/v2/live/${sessionID}/detail`,
          method: 'GET',
        })

        const currentUserDetailResponse = await apiClient({
          url: `/session/guest/v2/live/${sessionID}/user`,
          method: 'GET',
        })

        actions.setSessionData({
          ...get(sessionDetailResponse, 'data.data', {}),
          ...get(currentUserDetailResponse, 'data.data', {}),
        })
        actions.setLoading(false)
      } catch (error) {
        sendLog(
          `Failed to load session detail for ${sessionID} with error: ${error.message}`,
        )
        if (get(error, 'response.data.code') === 'FAIL') {
          actions.setError({
            errorMessage: get(error, 'response.data.errors', [
              'unknown error',
            ]).join(','),
            error,
          })
        } else {
          actions.setError({
            errorMessage: get(error, 'response.data.message'),
            error,
          })
        }
      }
    },
  ),
}

export default SessionDetailModel
