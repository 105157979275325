import { useEffect, useState } from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LineShareButton,
  LineIcon,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  EmailShareButton,
} from 'react-share'
import { useRouter } from 'next/router'
import { styled, useTheme } from '@mui/material'
import {
  ContentCopy,
  MoreHoriz,
  EmailRounded,
} from '@mui/icons-material'

import get from 'lodash/get'

import { isDesktop } from 'helpers/user-agent'
import {
  getStateFromSource,
  incrementPeopleProperties,
  trackEvent,
  unionListToPeopleProperties,
} from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'
import { sendLog } from 'helpers/log'
import { isGuestMode } from 'helpers/cookie'
import trackers from 'trackers'
import {
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage'

import { useContentDetailStore } from 'stores/domains/Content'
import { generateDeepLink } from 'services/promoter'
import { useStoreState } from 'stores/hooks'
import Button from '../Button'
import Box from '../Box'
import Typography from '../Typography'
import { generateShortUuid } from 'helpers/generate/uuid'
import { usePromoterStore } from 'stores/commons'
import { getErrorMessage } from 'helpers/error'

const StyledShareContent = styled(Box)({
  display: 'grid',
  gap: '24px 21px',
  gridTemplateColumns: 'repeat(4, 1fr)',
  alignItems: 'center',
  justifyItems: 'center',
  top: '20%',
  position: 'relative',
})

const StyledButtonCopy = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.tertiery,
  width: '48px',
  height: '48px',
  borderRadius: '100%',
  minWidth: '0',
  '&:hover': {
    backgroundColor: theme.palette.background.tertiery,
  },
}))

const StyledButtonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '48px',
})

const StyledTypography = styled(Typography)({
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: '400',
  marginTop: '8px',
})

interface IShareMediaSocial {
  linkData?: Object
  isCreator?: boolean
  isSupporter?: boolean
  isContent?: boolean
  isEvent?: boolean
  customQuote?: string
  customLink?: string
  isShareReferral?: boolean
  isSharePromoter?: boolean
  referralCode?: string
  abTestingUserGroup?: string
  trackerData?: any
  clickButton?: string
  productType?: string
  shareType?: ShareType
  trackerProperties?: any
  queryParams?: Object
  onClose: Function
}

type SocialMediaTypes =
  | 'Facebook'
  | 'Instagram'
  | 'Twitter'
  | 'Whatsapp'
  | 'Line'
  | 'Linkedin'
  | 'Telegram'
  | 'Message'
  | 'Mail'

type ShareType =
  | 'community'
  | 'event'
  | 'content'
  | 'creator'
  | 'supporter'

export default function ShareMediaSocial({
  linkData,
  isCreator = false,
  isSupporter = false,
  isContent = false,
  isEvent = false,
  customQuote,
  customLink = '',
  isShareReferral = false,
  isSharePromoter = false,
  referralCode = '',
  abTestingUserGroup = '',
  trackerData,
  clickButton = '',
  productType = 'content',
  shareType,
  trackerProperties,
  queryParams = [],
  onClose,
}: IShareMediaSocial) {
  const theme = useTheme()
  const { pathname } = useRouter()
  const [
    isLoadingGeneratedDeepLinkUrl,
    setIsLoadingGeneratedDeepLinkUrl,
  ] = useState(false)
  const [
    isErrorGeneratedDeepLinkUrl,
    setIsErrorGeneratedDeepLinkUrl,
  ] = useState(null)
  const [url, setUrl] = useState('')
  const [isDisabled, setDisabled] = useState(true)

  const {
    creatorProfile,
    sessionBooking,
    sessionDetail,
    participantList,
  } = useStoreState((state) => ({
    sessionBooking: state.sessionBooking,
    sessionDetail: state.sessionDetail,
    participantList: state.participantList,
    creatorProfile: state.creatorProfile,
  }))
  const { setErrorGenerateLink } = usePromoterStore((state) => ({
    setErrorGenerateLink: state.setErrorGenerateLink,
  }))
  const { contentDetail, contentUserDetail } = useContentDetailStore(
    (state) => ({
      contentDetail: state.contentDetailData,
      contentUserDetail: state.contentUserDetailData,
    }),
  )

  function handleShareSession(shareTo: SocialMediaTypes) {
    if (shareType === 'community') {
      trackers.community('submit_share_community', trackerData, {
        ...trackerProperties,
        community_share_to: shareTo,
      })
    } else {
      if (isShareReferral) {
        trackers.user('share_referral_code', trackerData, {
          modal_name: 'SHARE_REFERRAL_CODE',
          referral_code: referralCode,
          referral_code_share_to: shareTo,
        })
      } else {
        const sessionData = get(sessionDetail, 'sessionData', {})
        const profileData = get(
          creatorProfile,
          'creatorProfileData',
          {},
        )
        const creator =
          isCreator || isContent || isEvent
            ? get(profileData, 'creator', {})
            : get(sessionData, 'creator', {})
        const category =
          isCreator || isContent || isEvent
            ? get(profileData, 'category', {})
            : get(sessionData, 'creator.category', {})
        const content = isContent || isEvent ? contentDetail : {}
        const contentUser =
          isContent || isEvent ? contentUserDetail : {}

        const eventName = isCreator
          ? 'submit_share_creator'
          : isContent
          ? 'submit_share_content'
          : isEvent
          ? 'submit_share_event'
          : isSupporter
          ? 'submit_share_supporter'
          : 'share_session'
        const modalName = isCreator
          ? 'SHARE_CREATOR'
          : isContent
          ? 'SHARE_CONTENT'
          : isSupporter
          ? 'SHARE_SUPPORTER'
          : 'SHARE_SESSION'
        const creatorId = get(creator, 'creator_id', '')

        const basePayload = {
          state_name: getStateFromSource(pathname),
          modal_name: modalName,
        }
        const baseCreatorPayload = {
          creator_id: creatorId,
          creator_user_id: get(creator, 'creator_user_id', ''),
          creator_category: get(category, 'name', ''),
          creator_category_id: get(category, 'id', ''),
          is_creator_own_profile: get(creator, 'self', false),
        }
        const sessionPayload = {
          session_id: get(sessionData, 'session_id', ''),
          session_type: get(sessionData, 'session_type'),
          session_status: get(
            sessionData,
            'session_status',
            '',
          ).toLowerCase(),
          session_price_per_participant: get(sessionData, 'price', 0),
          max_num_participant: get(sessionData, 'slots', 0),
          session_title: get(sessionData, 'title', ''),
          session_description: get(sessionData, 'description', ''),
          estimate_session_start_datetime: get(
            sessionData,
            'start_time',
          ),
          estimate_session_duration:
            get(sessionData, 'duration', 0) * 60, // seconds,
          session_photo_image_path: get(sessionData, 'cover'),
          num_user_booked: get(sessionBooking, 'totalBooking', 0),
          list_user_booked: participantList.participants.map(
            (participant) => participant.user_id,
          ),
          session_share_to: shareTo,
        }
        const creatorPayload = {
          creator_name: get(creator, 'name', ''),
          creator_profile_share_to: shareTo,
        }
        const supporterPayload = {
          ...trackerProperties,
          supporter_share_to: shareTo,
          state_name: 'SUPPORTER_PROFILE',
        }
        const contentPayload = {
          creator_name: get(creator, 'name', ''),
          content_type: get(content, 'type.name', ''),
          content_id: get(content, 'product_id', ''),
          content_price_initial: get(contentUser, 'price', ''),
          content_price_final: get(contentUser, 'sale_price', ''),
          content_title: get(content, 'title', ''),
          content_description: get(content, 'description', ''),
          content_photo_image_path: get(content, 'cover', ''),
          content_items_sold: get(content, 'stats.total_sales', ''),
          content_duration: get(content, 'content_length', ''),
          content_share_to: shareTo || '',
          subscribe_to_creator: get(contentUser, 'is_subscriber', ''),
          content_access_source: get(
            contentUser,
            'content_access_source',
            'none',
          ),

          package_id: (
            get(contentUser, 'subscribe_package_list') || []
          ).map((item) => item.package_id),

          package_title: (
            get(contentUser, 'subscribe_package_list') || []
          ).map((item) => item.title),

          content_is_promoted: isSharePromoter,
        }

        if (isSharePromoter) {
          if (productType === 'content') {
            trackers.content('submit_promote_content', trackerData, {
              modal_name: 'CHOOSE_PROMOTION_CHANNEL',
              ab_testing_user_group: abTestingUserGroup,
              click_button: clickButton,
              content_promote_to: shareTo,
            })
          }
          if (productType === 'event') {
            trackers.event('submit_promote_event', trackerData, {
              modal_name: 'CHOOSE_PROMOTION_CHANNEL',
              event_promote_to: shareTo,
            })
          }
          if (productType === 'subscription') {
            trackers.subscription(
              'submit_promote_subscription',
              trackerData,
              {
                modal_name: 'CHOOSE_PROMOTION_CHANNEL',
                subscription_promote_to: shareTo,
              },
            )
          }
        } else {
          trackEvent(eventName, {
            ...basePayload,
            ...(!isSupporter ? baseCreatorPayload : {}),
            ...(isCreator
              ? creatorPayload
              : isContent
              ? contentPayload
              : isSupporter
              ? supporterPayload
              : sessionPayload),
          })

          incrementPeopleProperties({
            followed_creator_num: 1,
            lifetime_total_num_share_creator_profile: 1,
          })

          if (isContent) {
            incrementPeopleProperties({
              lifetime_total_num_share_content: 1,
            })
          }

          unionListToPeopleProperties({
            followed_creator_list: creatorId,
          })
        }
      }
    }
  }

  const generateDeepLinkHandler = async (url, idempotentKey) => {
    setIsLoadingGeneratedDeepLinkUrl(true)
    try {
      const response = await generateDeepLink(
        url.replace(window?.location?.origin, ''),
        idempotentKey,
      )
      setUrl(response?.link)
      setDisabled(false)
    } catch (error) {
      setDisabled(true)
      setIsErrorGeneratedDeepLinkUrl(error)
      setErrorGenerateLink(error)
      onClose()
      sendLog(error)
    } finally {
      setIsLoadingGeneratedDeepLinkUrl(false)
    }
  }

  useEffect(() => {
    if (!getLocalStorage('idempotent')) {
      setLocalStorage('idempotent', generateShortUuid())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      process.env.NEXT_IS_SERVER !== 'true' &&
      !isEmpty(customLink)
    ) {
      setDisabled(false)
      setUrl(customLink)
    } else if (
      //for promoter
      process.env.NEXT_IS_SERVER !== `true` &&
      !isGuestMode() &&
      isSharePromoter
    ) {
      setDisabled(false)
      setUrl(linkData?.[`data`]?.[`$canonical_url`])
    } else if (
      //for non-promoter (login and guest)
      process.env.NEXT_IS_SERVER !== 'true' &&
      linkData?.['data']?.['$canonical_url'] &&
      getLocalStorage('idempotent') &&
      window
    ) {
      const stringifiedQuery = getStringifiedQuery(queryParams)
      generateDeepLinkHandler(
        `${linkData?.['data']?.['$canonical_url']}${stringifiedQuery}`,
        getLocalStorage('idempotent'),
      )
    } else {
      setDisabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const quote = isEmpty(customQuote)
    ? `Yuk cek ini di TipTip! `
    : customQuote

  const copyLink = async () => {
    if (
      !isLoadingGeneratedDeepLinkUrl &&
      !isErrorGeneratedDeepLinkUrl
    ) {
      try {
        await navigator.clipboard.writeText(url)
      } catch (error) {
        sendLog(error)
      }
    } else {
      console.error('failed to copy')
    }
  }

  const shareData = {
    title: 'TipTip.id',
    text: quote,
    url: url,
  }

  const handleOtherLink = () => {
    if (
      window?.navigator !== undefined &&
      navigator.share &&
      navigator.canShare(shareData)
    ) {
      navigator
        .share(shareData)
        .catch((err) => sendLog(getErrorMessage(err)))
    }
  }

  return (
    <StyledShareContent data-testid="share-mediasocial-container">
      <StyledButtonContainer>
        <StyledButtonCopy
          disableElevation
          fullWidth
          onClick={() => {
            handleShareSession('Message')
            copyLink()
          }}
          disabled={isDisabled}
        >
          <ContentCopy sx={{ color: theme.palette.icon.secondary }} />
        </StyledButtonCopy>
        <StyledTypography>Copy</StyledTypography>
      </StyledButtonContainer>
      <StyledButtonContainer>
        <WhatsappShareButton
          style={{ height: '48px', width: '48px' }}
          title={quote}
          url={url}
          disabled={isDisabled}
          onClick={() => handleShareSession('Whatsapp')}
        >
          <WhatsappIcon round={true} size={48}></WhatsappIcon>
        </WhatsappShareButton>
        <StyledTypography>Whatsapp</StyledTypography>
      </StyledButtonContainer>
      <StyledButtonContainer>
        <FacebookShareButton
          // quote prop is no longer working
          url={url}
          disabled={isDisabled}
          onClick={() => handleShareSession('Facebook')}
        >
          <FacebookIcon
            round={true}
            style={{ height: '48px', width: '48px' }}
          ></FacebookIcon>
        </FacebookShareButton>
        <StyledTypography>Facebook</StyledTypography>
      </StyledButtonContainer>
      <StyledButtonContainer>
        <TwitterShareButton
          style={{ height: '48px', width: '48px' }}
          title={quote}
          url={url}
          disabled={isDisabled}
          onClick={() => handleShareSession('Twitter')}
        >
          <TwitterIcon round={true} size={48}></TwitterIcon>
        </TwitterShareButton>
        <StyledTypography>Twitter</StyledTypography>
      </StyledButtonContainer>
      <StyledButtonContainer>
        <LinkedinShareButton
          // title prop is no longer working
          style={{ height: '48px', width: '48px' }}
          url={url}
          disabled={isDisabled}
          onClick={() => handleShareSession('Linkedin')}
        >
          <LinkedinIcon round={true} size={48}></LinkedinIcon>
        </LinkedinShareButton>
        <StyledTypography>LinkedIn</StyledTypography>
      </StyledButtonContainer>
      <StyledButtonContainer>
        <LineShareButton
          style={{ height: '48px', width: '48px' }}
          url={url}
          title={`${quote}`}
          disabled={isDisabled}
          onClick={() => handleShareSession('Line')}
        >
          <LineIcon round={true} size={48}></LineIcon>
        </LineShareButton>
        <StyledTypography>Line</StyledTypography>
      </StyledButtonContainer>
      <StyledButtonContainer>
        <TelegramShareButton
          style={{ height: '48px', width: '48px' }}
          url={`${quote} ${url}`}
          disabled={isDisabled}
          onClick={() => handleShareSession('Telegram')}
        >
          <TelegramIcon round={true} size={48}></TelegramIcon>
        </TelegramShareButton>
        <StyledTypography>Telegram</StyledTypography>
      </StyledButtonContainer>
      <StyledButtonContainer>
        {isDesktop() ? (
          <>
            <EmailShareButton
              style={{ height: '48px', width: '48px' }}
              subject="TipTip.id"
              body={quote}
              url={url}
              disabled={isDisabled}
              onClick={(_, link) => {
                window.location.href = link
                handleShareSession('Mail')
              }}
            >
              <Box
                sx={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '24px',
                  background: theme.palette.background.tertiery,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EmailRounded
                  sx={{
                    color: theme.palette.icon.secondary,
                    fontSize: '30px',
                  }}
                />
              </Box>
              {/* <EmailIcon
                bgStyle={{
                  fill: theme.palette.background.tertiery,
                }}
                iconFillColor={theme.palette.icon.secondary}
                round={true}
                size={48}
              ></EmailIcon> */}
            </EmailShareButton>
            <StyledTypography>Email</StyledTypography>
          </>
        ) : (
          <>
            <StyledButtonCopy
              disableElevation
              fullWidth
              onClick={() => handleOtherLink()}
              disabled={isDisabled}
            >
              <MoreHoriz
                sx={{ color: theme.palette.icon.secondary }}
              />
            </StyledButtonCopy>
            <StyledTypography>Lainnya</StyledTypography>
          </>
        )}
      </StyledButtonContainer>
    </StyledShareContent>
  )
}

function getStringifiedQuery(queryParams) {
  const hasQueryParams = Object.keys(queryParams).length
  const joinedQueryParams = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&')
  return hasQueryParams ? `?${joinedQueryParams}` : ''
}
