import { setUserDevice } from 'helpers/user-device'
import { postTopupRequest, topUpCoinPayment } from 'services/payment'
import {
  ICheckoutParams,
  IPaymentHandlerStrategy,
  IPreCheckoutResult,
  PaymentHandlerStrategy,
} from 'types/payment'

const topupCoinPaymentHandler: PaymentHandlerStrategy = ({
  idempotentKey,
  topupInquiry,
}: IPaymentHandlerStrategy) => {
  const preCheckout = async (): Promise<IPreCheckoutResult> => {
    const topUpResponse = await topUpCoinPayment({
      coins: topupInquiry?.coins,
      idempotentKey,
    })
    return {
      result: { ...topUpResponse, use_default_admin_fee_title: true },
      isCoinPaymentDefault: false,
      skipPreCheckout: false,
    }
  }

  const checkout = async ({
    paymentMethodCode,
    paymentId,
  }: ICheckoutParams) => {
    // send user device information
    setUserDevice()
    return postTopupRequest({
      payment_id: paymentId,
      payment_method_code: paymentMethodCode,
      idempotentKey,
    })
  }

  return {
    preCheckout,
    checkout,
    itemType: 'coin',
  }
}

export default topupCoinPaymentHandler
