import { MouseEventHandler, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Radio, useTheme } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Box from 'components/commons/Box'
import {
  StyledHelpIcon,
  StyledIconImg,
  StyledIconRadio,
  StyledInfoRadio,
  StyledWrapperInfo,
  TopUpButton,
} from '../../Wallet/DirectPaymentModal/styled'
import { StyledDivider } from 'components/domains/Wallet/DirectPaymentDrawerContent/styled'
import {
  IPaymentMethod,
  IXenditTokenizationResponse,
} from 'types/payment'
import isEmpty from 'helpers/is-empty'
import PaymentCardIcon from 'components/domains/Payment/PaymentCardIcon'

interface IPaymentMethodItemProps {
  onClickCardItem: MouseEventHandler<HTMLDivElement | SVGSVGElement>
  isPageView?: boolean
  selectedPaymentMethodIndex: number
  paymentMethodIndex: number
  isDisabled: boolean
  paymentMethod: IPaymentMethod
  onClickHelpIcon?: MouseEventHandler<SVGSVGElement>
  renderHelpIcon?: boolean
  renderWidgetCoinError?: boolean
  onClickTopUp?: MouseEventHandler<HTMLButtonElement>
  tokenizationData?: IXenditTokenizationResponse
  useBlackIcon?: boolean
}
const PaymentMethodItem = ({
  onClickCardItem,
  isPageView,
  selectedPaymentMethodIndex,
  paymentMethodIndex,
  isDisabled,
  paymentMethod,
  onClickHelpIcon,
  renderHelpIcon,
  renderWidgetCoinError,
  onClickTopUp,
  tokenizationData,
  useBlackIcon = true,
}: IPaymentMethodItemProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const cardInfo = useMemo(() => {
    return {
      maskedCardNumber:
        tokenizationData?.masked_card_number.slice(-4),
      bankName: tokenizationData?.card_info?.bank,
      cardType: tokenizationData?.card_info?.type.toLowerCase(),
    }
  }, [tokenizationData])
  return (
    <Box
      onClick={onClickCardItem}
      data-testid="c-payment-method-item"
      id={'b-payment-card-details-open'}
    >
      <Box>
        <FormControlLabel
          value={paymentMethodIndex}
          control={<Radio disabled={isDisabled} />}
          label={
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>
                <StyledIconRadio
                  sx={{
                    color: isEmpty(paymentMethod?.disable_reason)
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
                  }}
                >
                  <Box
                    sx={{
                      width: '20px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginRight: '10px',
                    }}
                  >
                    <StyledIconImg
                      sx={{
                        width: '100%',
                        padding:
                          paymentMethod?.code === 'TIPTIP_COIN'
                            ? '2px'
                            : '0px !important',
                      }}
                      src={
                        useBlackIcon
                          ? paymentMethod?.black_icon
                          : paymentMethod?.icon
                      }
                    />
                  </Box>
                  {paymentMethod?.name}
                  {renderHelpIcon && (
                    <StyledHelpIcon onClick={onClickHelpIcon} />
                  )}
                </StyledIconRadio>
                {!isEmpty(paymentMethod?.additional_info) && (
                  <StyledWrapperInfo data-testid="c-payment-additional-info">
                    <StyledInfoRadio>
                      {paymentMethod.additional_info}
                    </StyledInfoRadio>
                  </StyledWrapperInfo>
                )}
                {renderWidgetCoinError && (
                  <StyledWrapperInfo data-testid="c-payment-info-insufficient-balance">
                    <StyledInfoRadio
                      sx={{
                        color: theme.palette.error.main,
                      }}
                    >
                      {t('paymentMethodV2WidgetCoinError', {
                        balance: paymentMethod?.coin?.balance,
                      })}
                    </StyledInfoRadio>
                  </StyledWrapperInfo>
                )}
                {paymentMethod?.disable_reason && (
                  <StyledWrapperInfo>
                    <StyledInfoRadio>
                      {paymentMethod?.disable_reason}
                    </StyledInfoRadio>
                  </StyledWrapperInfo>
                )}
              </Box>
              {renderWidgetCoinError && (
                <TopUpButton
                  id={'b-payment-top-up-coin'}
                  data-testid={'b-payment-top-up-coin'}
                  disabled={isDisabled}
                  buttonVariant="outlined"
                  onClick={onClickTopUp}
                >
                  {t('topUpCoinButtonTitle')}
                </TopUpButton>
              )}
            </Box>
          }
          sx={{
            width: '100%',
            backgroundColor: isPageView
              ? theme.palette.background.secondary
              : selectedPaymentMethodIndex === paymentMethodIndex &&
                theme.palette.background.secondary,
            marginBottom: isPageView ? '12px' : 0,
            borderRadius: '6px',
            padding: '8px 8px',
            marginRight: '0px',
            '& .MuiFormControlLabel-label': {
              display: 'flex',
              width: '100%',
            },
            '& .MuiSvgIcon-root': {
              fontSize: 20,
            },
            '& .MuiRadio-root.Mui-disabled': {
              color: theme.palette.text.tertiery,
            },
            marginLeft: 0,
            opacity: isDisabled ? 0.5 : 1,
          }}
        />
      </Box>

      {selectedPaymentMethodIndex === paymentMethodIndex &&
        tokenizationData &&
        paymentMethod?.code === 'PAYMENT_CARD' && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: isPageView
                ? theme.palette.background.tertiery
                : selectedPaymentMethodIndex === paymentMethodIndex &&
                  theme.palette.background.tertiery,
              marginBottom: isPageView ? '12px' : 0,
              borderRadius: '6px',
              padding: '8px 8px',
            }}
          >
            <Box
              sx={{
                display: 'block',
                justifyContent: 'space-between',
                marginLeft: '30px',
              }}
            >
              <FormControlLabel
                value={paymentMethodIndex}
                control={<Radio />}
                label={
                  <StyledIconRadio>
                    <Box
                      sx={{
                        width: '20px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: '10px',
                      }}
                    >
                      <PaymentCardIcon
                        cardBrand={tokenizationData?.card_info?.brand?.toLowerCase()}
                      />
                    </Box>
                    <Box>
                      Bank {cardInfo?.bankName}
                      <StyledWrapperInfo>
                        <StyledInfoRadio
                          sx={{
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {cardInfo?.cardType === 'debit'
                            ? t('key_debit_card')
                            : t('key_credit_card')}{' '}
                          ** {cardInfo?.maskedCardNumber}
                        </StyledInfoRadio>
                      </StyledWrapperInfo>
                    </Box>
                  </StyledIconRadio>
                }
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 20,
                  },
                }}
              />
            </Box>
            <ArrowForwardIosIcon
              id={'b-payment-card-details-edit'}
              onClick={onClickCardItem}
              sx={{
                cursor: 'pointer',
                color: theme.palette.text.secondary,
                fontSize: '12px',
                marginRight: '16px',
              }}
            />
          </Box>
        )}

      {!isPageView && <StyledDivider />}
    </Box>
  )
}

export default PaymentMethodItem
