import MaintenanceBody from 'components/domains/Home/MaintenanceBody'
import ErrorResponseCode from 'components/commons/ErrorResponseCode'
import redirect from 'helpers/redirect'
import appConfig from 'configs/app'
import { getIdToken } from 'helpers/auth'
import { ErrorActionType } from 'types/common'

export type ErrorPaymentCode =
  | 'INSUFFICIENT_QUOTA'
  | 'SESSION_FULLY_BOOKED'
  | 'USER_OVERBOOKED'
  | 'NOT_ENOUGH_QUOTA'
  | 'CHECKOUT_INVALID_STATUS'
  | 'FAIL_CHECKOUT'
  | 'FAIL'
  | 'INSUFFICIENT_BALANCE_COIN'
  | 'SALE_NOT_STARTED'
  | 'INVALID_SALES_PERIOD'
  | 'PRODUCT_UNAVAILABLE'
  | 'BAD_REQUEST_COIN'
  | 'BOOKING_EXPIRED'
  | 'NOT_FOUND'
  | 'ID_NUMBER_ALREADY_USED'
  | 'GUEST_PENDING_TRANSACTION'
  | 'INVALID_VOUCHER'
  | 'GENERAL_ERROR_PAYMENT'

type ErrorPayment = {
  code?: ErrorPaymentCode
  message?: string
  status?: number
  data?: any
  requestId?: string
}

export interface IErrorPayment {
  setShowTopUpModal?: Function
  cancelTransaction?: Function
  onCloseErrorPayment: Function
  onRetryHandler: Function
  onContinueTransaction: Function
  isPaymentLoading: boolean
  isPageView?: boolean
  errorData: ErrorPayment
}

type PaymentErrorMapping = () => {
  onCTA: (params) => void
  data?: any
  disabled?: boolean
}

export default function ErrorPayment({
  setShowTopUpModal,
  cancelTransaction,
  onRetryHandler,
  onCloseErrorPayment,
  onContinueTransaction,
  isPaymentLoading,
  isPageView,
  errorData,
}: IErrorPayment) {
  const handleMappingPaymentError: PaymentErrorMapping = () => {
    switch (errorData.code) {
      case 'INSUFFICIENT_QUOTA':
        return {
          onCTA: (cta: ErrorActionType) => {
            switch (cta) {
              case ErrorActionType.BROWSE_EVENT:
                redirect('/browse?status=event')
                break
              case ErrorActionType.BACK_TO_EVENT:
                if (!isPageView) window.location.reload()
                break
            }
          },
        }
      case 'SESSION_FULLY_BOOKED':
      case 'NOT_ENOUGH_QUOTA':
      case 'CHECKOUT_INVALID_STATUS':
      case 'BOOKING_EXPIRED':
      case 'NOT_FOUND':
        return {
          onCTA: () => {
            onCloseErrorPayment()
            if (!isPageView) window.location.reload()
          },
        }
      case 'USER_OVERBOOKED':
      case 'SALE_NOT_STARTED':
      case 'INVALID_SALES_PERIOD':
      case 'PRODUCT_UNAVAILABLE':
      case 'INVALID_VOUCHER':
        return {
          onCTA: () => onCloseErrorPayment(),
        }
      case 'FAIL_CHECKOUT':
        return {
          onCTA: (cta: ErrorActionType) => {
            switch (cta) {
              case ErrorActionType.RETRY:
                onRetryHandler()
                break
              case ErrorActionType.TRANSACTION_HISTORY:
                if (getIdToken()) {
                  redirect(`/transaction-history`)
                } else {
                  onCloseErrorPayment()
                }
                break
            }
          },
        }
      case 'BAD_REQUEST_COIN':
        return {
          onCTA: () => onRetryHandler(),
          data: errorData.message,
        }
      case 'INSUFFICIENT_BALANCE_COIN':
        return {
          onCTA: (cta: ErrorActionType) => {
            switch (cta) {
              case ErrorActionType.BACK:
                onRetryHandler()
                break
              case ErrorActionType.TOPUP:
                setShowTopUpModal()
                onRetryHandler()
                break
            }
          },
        }
      case 'ID_NUMBER_ALREADY_USED':
        return {
          data: errorData.data,
          onCTA: (cta: ErrorActionType) => {
            switch (cta) {
              case ErrorActionType.CONTACT_CS:
                window.open(
                  `${appConfig.supportCenterUrl}home`,
                  '_blank',
                )
                break
              case ErrorActionType.CLOSE:
                onCloseErrorPayment()
                break
            }
          },
        }
      case 'GUEST_PENDING_TRANSACTION':
        return {
          onCTA: (cta: ErrorActionType) => {
            switch (cta) {
              case ErrorActionType.CANCEL:
                cancelTransaction()
                break
              case ErrorActionType.CONTINUE:
                onContinueTransaction()
                break
            }
          },
          data: errorData.data,
          disabled: isPaymentLoading,
        }
      case 'GENERAL_ERROR_PAYMENT':
      default:
        return {
          onCTA: () => onRetryHandler(),
        }
    }
  }
  const ErrorRender = () => {
    if (errorData.status === 503) {
      return <MaintenanceBody isButtonHide />
    }

    return (
      <ErrorResponseCode
        code={errorData.code}
        showCTA
        onCTA={handleMappingPaymentError().onCTA}
        sxContainer={{ paddingBottom: { xs: '16px', md: 0 } }}
        data={handleMappingPaymentError().data}
        disabled={handleMappingPaymentError().disabled}
        requestId={errorData.requestId}
      />
    )
  }

  return <ErrorRender />
}
