import { sendLog } from 'helpers/log'

// TO-DO: Move to helpers/navigator
export const copyToClipboard = async (
  text: string,
  callback: Function = () => {},
) => {
  try {
    await navigator.clipboard.writeText(text)
    callback()
  } catch (error) {
    sendLog(error)
  }
}
