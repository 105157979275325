import { styled } from '@mui/material/styles'
import { Stack, TextField } from '@mui/material'
import theme from 'theme'

export const StyledStack = styled(Stack)({
  width: '100%',
  minHeight: '180px',
  overflowY: 'scroll',
  gap: '8px',
  '::-webkit-scrollbar': {
    width: 0,
    background: 'transparent' /* make scrollbar transparent */,
  },
  '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
  'scrollbar-width': 'none',
})

export const StyledSelectContainer = styled(Stack)(({ theme }) => ({
  minWidth: '85px',
  height: '100%',
  alignItems: 'center',
  padding: '0px 10px',
  borderRadius: '6px',
  border: `1px solid ${theme.palette.border.minimal}`,
  background: theme.palette.background.tertiery,
  color: 'black',
  maxHeight: '44px',
  lineHeight: '44px',
  justifyContent: 'space-between',
}))

export const StyledDesktopContainer = styled('div')(({ theme }) => ({
  width: '300px',
  zIndex: 1,
  backgroundColor: 'white',
  padding: '8px',
  height: 312,
  borderRadius: `12px`,
  background: theme.palette.secondary.main,
  boxShadow: `0px 0px 8px 0px ${theme.palette.grey[300]}`,
}))

export const StyledTextField = styled(TextField)({
  width: '100%',
  display: 'flex',
  height: '44px',
  borderRadius: `100px`,
  background: theme.palette.background.tertiery,
  '& .MuiOutlinedInput-root': {
    height: '44px',
    '& fieldset': {
      border: 'unset',
    },
    '&:hover fieldset': {
      border: 'unset',
    },
    '&.Mui-focused fieldset': {
      border: 'unset',
    },
  },
  input: {
    fontSize: '14px',
  },
})

export const StyledCountryWrapper = styled(Stack)({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  padding: '13px 12px',
  gap: `8px`,
  borderRadius: `6px`,
  background: theme.palette.background.secondary,
  justifyContent: 'space-between',
})
