import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import { isGuestMode } from 'helpers/cookie'
import { IProductsProps } from 'services/payment'

export const getSectionList = async () => {
  return await apiClient({
    url: 'campaign/guest/v1/section',
    method: 'GET',
  })
}

interface IContentPayload {
  page: number
  limit: number
  q?: string
  category_id?: string
  type?: string
  sort_by?: string
  sort?: string
}

export const getSectionDetail = async (
  url: string,
  params?: IContentPayload,
) => {
  return await apiClient({
    url,
    method: 'GET',
    params,
    token: isGuestMode() ? null : getIdToken(),
  })
}

export const getCheckinFlag = async (token?: string) => {
  if (!token) return {}
  const response = await apiClient({
    url: '/campaign/v1/checkin/flag',
    method: 'GET',
    token: token,
  })
  return response?.data
}

export const getCheckinList = async (token?: string) => {
  const url = token
    ? '/campaign/v1/checkin/list'
    : '/campaign/guest/v1/checkin/list'
  const response = await apiClient({
    url,
    method: 'GET',
    token: token,
  })
  return response?.data
}

export const postCheckinClaim = async (
  id: string,
  token?: string,
) => {
  if (!token) return {}
  const response = await apiClient({
    url: '/campaign/v1/checkin/claim',
    method: 'POST',
    data: {
      id,
    },
    token: token,
  })
  return response?.data
}

interface IVoucherInboxBody {
  products: Array<IProductsProps>
  payment_method: string
  idempotentKey: string
  checkout_source: string
}
interface IVoucherCheckBody {
  products: Array<IProductsProps>
  payment_method: string
  user_mask_id?: string
  voucher_code: string
  idempotentKey: string
  checkout_source: string
}

interface IEventVoucherCheckBody {
  payment_method: string
  book_session_id?: string
  voucher_code: string
  idempotentKey: string
}

export const voucherInbox = async ({
  products,
  payment_method,
  idempotentKey,
  checkout_source,
}: IVoucherInboxBody) => {
  try {
    const response = await apiClient({
      url: `/campaign/v2/voucher/inbox`,
      method: 'POST',
      token: getIdToken(),
      data: {
        products: products,
        payment_method: payment_method,
        checkout_source: checkout_source,
      },
      headers: { 'Idempotent-Key': idempotentKey },
    })
    return response.data.data
  } catch (error) {
    throw error
  }
}

export const voucherCheck = async ({
  products,
  payment_method,
  voucher_code,
  idempotentKey,
  checkout_source,
}: IVoucherCheckBody) => {
  try {
    const response = await apiClient({
      url: `/order/v4/voucher/check`,
      method: 'POST',
      token: getIdToken(),
      data: {
        products: products,
        payment_method: payment_method,
        voucher_code: voucher_code,
        checkout_source: checkout_source,
      },
      headers: { 'Idempotent-Key': idempotentKey },
    })
    return response.data.data
  } catch (error) {
    throw error
  }
}

export const eventVoucherCheck = async ({
  book_session_id,
  payment_method,
  voucher_code,
  idempotentKey,
}: IEventVoucherCheckBody) => {
  try {
    const response = await apiClient({
      url: `/order/v1/event/voucher/check`,
      method: 'POST',
      token: getIdToken(),
      data: {
        book_session_id,
        payment_method,
        voucher_code,
      },
      headers: { 'Idempotent-Key': idempotentKey },
    })
    return response.data.data
  } catch (error) {
    throw error
  }
}

export const eventVoucherCheckV2 = async ({
  book_session_id,
  payment_method,
  voucher_code,
  idempotentKey,
}: IEventVoucherCheckBody) => {
  try {
    const response = await apiClient({
      url: `/order/v2/event/voucher/check`,
      method: 'POST',
      token: getIdToken(),
      data: {
        book_session_id,
        payment_method_code: payment_method,
        voucher_code,
      },
      headers: { 'Idempotent-Key': idempotentKey },
    })
    return response.data.data
  } catch (error) {
    throw error
  }
}

export async function getSecondaryBanner() {
  return await apiClient({
    url: '/campaign/guest/v1/home/banner',
    method: 'GET',
    params: {
      banner_type_id: 1,
    },
  })
}
