export default {
  baseApiUrl: process.env.NEXT_PUBLIC_API_URL,
  baseApiDomain: process.env.NEXT_PUBLIC_API_DOMAIN,
  customMetric: {
    request: 'api_call_request',
    response: 'api_call_response',
    httpCache: 'api_call_response',
    error: 'api_call_error',
  },
  channelPartner: 'TWA-PARTNER',
}
